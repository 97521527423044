<template>
    <div class="mobileHeader" id="mobileHeader">
      <div class="herder" @click="goHome">
        <img src="@/assets/icon/navIcon.png" alt="" class="herder1" @click.stop="isOpenNav">
        <img src="@/assets/icon/icon.png" alt="" class="herderc">
        <img src="@/assets/icon/searchYellow.png" style="opacity: 0;" alt="" class="herder1">
      </div>
        <div class="navBoxmeng" v-show="mobileIsHeaderOpenNav" @click="isOpenNav">
          <div class="navBox">
            <el-tree
              :data="path"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick"
            >
            <template #default="{ data }">
              <div :class="data.isclick ? 'origin':''">
                {{data.label }}
              </div>
            </template>
            </el-tree>
          </div>
        </div>
        
    </div>
  </template>
  <script setup name="mobileHeader">
  import { reactive ,computed, ref} from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { getCarouselImage,getProjectList,getNewsList,getActivityList,getSchoolList } from '@/api/home/index'
  import {jsonObj,imgUrl} from '@/utlis/constJSON.js'
  import { useStore } from "vuex";
  const store = useStore()
  const emit = defineEmits(['open'])
  let projectList = ref([])
  let path = []
  getProjectList().then(res => {
    projectList.value = res.items;
    console.log(projectList.value);
    store.dispatch('updateProjectArr',res.items)
    path = computed(() => store.state.path);
    path.value.forEach((item,index) => {
      // if(item.path === '/serve'){
      //   projectList.value.forEach((it,ins) => {
      //     path.value[index].children[ins].label = it.label
      //     path.value[index].children[ins].path = '/cultivate' + (ins + 1)
      //     path.value[index].children[ins].id = it.id
      //   })
      // }
    })
    console.log(path.value,"ppppppp");

  })

  let drawer = ref('')

  //新闻列表
  let newsArr = ref([])
  getNewsList().then(res => {
    newsArr.value = res.items
    store.dispatch('updateNewsArr',res.items)
  })
  //活动列表
  let activityList = ref([]);
  getActivityList().then(res => {
    activityList.value = res.items;
    store.dispatch('updateActivityArr',res.items)
  })
  
  const mobileIsHeaderOpenNav = computed(() => store.state.mobileIsHeaderOpenNav);
  const router = useRouter()
  const state = reactive({
  })
  const goHome = () => {
    router.push({
      path: '/'
    })
  }
  const defaultProps = {
    children: 'children',
    label: 'label',
  }
  const isOpenNav = ()=>{
    // store.dispatch('updatemobileIsHeaderOpenNav',!mobileIsHeaderOpenNav.value)
    emit('open')
  }
  
  const handleNodeClick = (e) => {
    if(e.children){
      return
    }
    router.push({
      path: e.path,
      query:{
        id: e.id
      },
    })
    
    path.value.forEach(v=>{
      v.isclick = false
      if(v?.children){
        v.children.forEach(m=>{
          m.isclick = false
          if(m.path == e.path){
            m.isclick = !m.isclick
            v.isclick = true
          }
        })
      }else{
        if(v.path == e.path){
          v.isclick = true
        }
      }
    })
    store.dispatch('updatePath',path.value)
    store.dispatch('updatemobileIsHeaderOpenNav',false)
  }
  </script>
  <style lang="scss" scoped>
    .mobileHeader{
      width: 100%;
      background: #004163;
      color: #fff;
      position: fixed;
      top: 0;
      z-index: 100;
      .herder{
        padding: 0 4rem;
        height: 11.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .herder1{
          width: 5.5rem;
        }
        .herderc{
          width: 42rem;
        }
      }
      .navBoxmeng{
        width: 100%;
        position: absolute;
        left:0;
        top: 15.75rem;
        z-index: 1;
        background-color: rgba(127, 127, 127,0.3);
      }
      .navBox{
        position: absolute;
        top:0;
        left: 0;
        z-index: 1;
        width: 75%;
        border: 1px solid #f7f3f3;
      }
        :deep() .el-tree-node__content {
          padding: 5.25rem 0 5.25rem 5.25rem;
          font-weight: 700;
          color: #004163;
          font-size: 3.15rem;
        }
        :deep() .el-tree-node__content{
          border-bottom: 1px solid #f7f3f3;
        }
        .origin{
          color: #F5AA3B;
        }
    }
    .fade-left-enter-from {
      opacity: 0;
      transform: translate(-100%, 0);
    }

    .fade-left-enter-to {
      opacity: 1;
      transform: translate(0, 0);
    }

    .fade-left-leave-from {
      opacity: 1;
      transform: translate(0, 0);
    }

    .fade-left-leave-to {
      opacity: 0;
      transform: translate(-100%, 0);
    }
  </style>
  