export const path = [
    {
      path: "/home",
      name: "homes",
      chinese:"首页",
      label:'TRANG CHỦ',
      isclick:false,
      meta: {
        title: 'EIC Education - Tư vấn học bổng du học Trung Quốc 2025',
        keywords: 'Du học Trung Quốc,Học bổng Du học Trung Quốc',
        description: 'EIC Education - Công ty Tư vấn học bổng du học Trung Quốc uy tín nhất. Hỗ trợ dịch thuật công chứng, hồ sơ, apply học bổng hơn 200 trường ĐH Top đầu'
      },
      component: () =>import( "@/views/home.vue"),
    },
    {
      chinese:'找学校和专业',
      path: "/school",
      name: "school",
      isclick:false,
      id:1,
      label:'TÌM TRƯỜNG, NGÀNH HỌC',
      meta: {
        title: 'Du học và Xin học bổng'
      },
      children:[
        {
          chinese:'找专业',
          path: "/specialty",
          name: "specialty",
          isclick:false,
          pId:1,
          label:'TÌM NGÀNH',
          meta: {
            title: 'Các ngành học tại Trung Quốc - Chọn trường du học theo ngành nghề',
            keywords: 'Các ngành du học Trung Quốc,Các ngành đào tạo tại Trung Quốc',
            description: 'Xem tất cả ngành nghề và chương trình đào tạo tại Trung Quốc và chọn trường du học theo ngành nghề'
          },
          component: () =>import( "@/views/specialty.vue"),
        },
        {
          chinese:'找学校',
          path: "/schoolmajor",
          name: "schoolmajor",
          isclick:false,
          pId:1,
          label:'TÌM TRƯỜNG',
          meta: {
            title: 'EIC Education - Công cụ tìm kiếm thông tin trường Đại học Trung Quốc',
            keywords: 'Các trường Đại học Trung Quốc,Danh sách các trường Đại học Trung Quốc',
            description: 'Chọn trường du học từ danh sách các trường đại học Trung Quốc. Xem các ngành đào tạo của trường cũng như nhận xét đánh giá của các học viên'
          },
          component: () =>import( "@/views/schoolmajor.vue"),
        },
      ]
    },
    {
      path: "/serve",
      chinese:'培训和出国留学',
      name: "serve",
      isclick:false,
      id:2,
      label:'DỊCH VỤ',
      meta: {
        title: 'Đào tạo tiếng Trung - Luyện thi chứng chỉ HSK',
        keywords: 'Trung tâm dạy tiếng Trung;Luyện thi HSK,Trung tâm dạy tiếng Trung uy tín nhất;Trung tâm luyện thi HSK',
        description: 'EIC Education - Chuyên gia đào tạo tiếng Trung - Luyện thi chứng chỉ HSK với đội ngũ giảng viên bản ngữ giàu kinh nghiệm.'
      },
      children:[
        {
          path: "/cultivate1",
          chinese:'培训',
          name: "cultivate1",
          isclick:false,
          pId:2,
          label:'DỊCH VỤ',
          meta: {
            title: 'Đào tạo tiếng Trung - Luyện thi chứng chỉ HSK',
            keywords: 'Trung tâm dạy tiếng Trung;Luyện thi HSK,Trung tâm dạy tiếng Trung uy tín nhất;Trung tâm luyện thi HSK',
            description: 'EIC Education - Chuyên gia đào tạo tiếng Trung - Luyện thi chứng chỉ HSK với đội ngũ giảng viên bản ngữ giàu kinh nghiệm.'
          },
          component: () =>import( "@/views/project/page1.vue"),
        },
        {
          path: "/cultivate2",
          chinese:'培训',
          name: "cultivate2",
          isclick:false,
          pId:2,
          label:'ĐÀO TẠO TIẾNG TRUNG',
          meta: {
            title: 'Thông tin các dịch vụ EIC Education cung cấp',
            keywords: 'Trung tâm Tư vấn du học Trung Quốc,Trung tâm Tư vấn du học Trung Quốc uy tín nhất',
            description: 'EIC Education - Chuyên gia tư vấn Du học Trung Quốc, 25 năm kinh nghiệm, 200 trường đối tác, 10,000 hồ sơ thành công'
          },
          component: () =>import( "@/views/project/page2.vue"),
        },
        {
          path: "/cultivate3",
          chinese:'培训',
          name: "cultivate3",
          isclick:false,
          pId:2,
          label:'DU HỌC ĐẠI HỌC',
          meta: {
            title: 'Dịch vụ - Tư vấn du học dài hạn',
            keywords: 'Tư vấn du học dài hạn Trung Quốc',
            description: 'EIC Education - Chuyên gia tư vấn các chương trình du học Trung Quốc dài hạn hệ đại học và sau đại học. Hỗ trợ xin học bổng các trường hàng đầu.'
          },
          component: () =>import( "@/views/project/page3.vue"),
        },
        {
          path: "/cultivate4",
          chinese:'培训',
          name: "cultivate4",
          isclick:false,
          pId:2,
          label:'DU HỌC NGẮN HẠN',
          meta: {
            title: 'Dịch vụ - Tư vấn du học ngắn hạn',
            keywords: 'Tư vấn du học 1 kỳ Trung Quốc,Tư vấn du học 1 năm Trung Quốc',
            description: 'EIC Education - Chuyên gia tư vấn các chương trình du học Trung Quốc 1 kỳ tiếng, 1 năm tiếng, trại đông, trại hè. Hỗ trợ xin học bổng các trường hàng đầu.'
          },
          component: () =>import( "@/views/project/page4.vue"),
        },
        // {
        //   path: "/cultivate",
        //   chinese:'培训',
        //   name: "cultivate",
        //   isclick:false,
        //   pId:2,
        //   label:'HỌC TIẾNG TRUNG',
        //   meta: {
        //       title: 'Tư vấn du học Trung Quốc'
        //     },
        //   component: () =>import( "@/views/project/page1.vue"),
        // },
        // {
        //   path: "/activityDetail",
        //   chinese:'培训',
        //   name: "activityDetail",
        //   isclick:false,
        //   pId:2,
        //   label:'HỌC TIẾNG TRUNG',
        //   meta: {
        //       title: 'Tư vấn du học Trung Quốc'
        //     },
        //   component: () =>import( "@/views/activityDetail.vue"),
        // },
        // {
        //   path: "/studyAbroadLong",
        //   chinese:'长期出国留学',
        //   name: "studyAbroadLong",
        //   isclick:false,
        //   pId:2,
        //   label:'DU HỌC DÀI HẠN',
        //   meta: {
        //       title: 'Tư vấn du học Trung Quốc'
        //     },
        //   component: () =>import( "@/views/studyAbroadLong.vue"),
        // }
      ]
    },
    { 
        path: "/scholarship",
        label:'HỌC BỔNG',
        name: "scholarship",
        isclick:false,
        id:3,
        chinese:'奖学金',
        meta: {
          title: 'Tư vấn săn học bổng du học Trung Quốc',
          keywords: 'Học bổng Trung QuốcHọc bổng du học Trung Quốc,Tư vấn săn học bổng du học Trung Quốc',
          description: 'Tư vấn săn học bổng du học Trung Quốc. Tìm kiếm học bổng, đăng ký săn học bổng du học Trung Quốc. ☏Hotline: 0984 133 380. Xem ngay!'
        },
        component: () =>import( "@/views/scholarship.vue"),
    },{ 
      path: "/news1",
      label:'TIN TỨC, HOẠT ĐỘNG',
      name: "news1",
      isclick:false,
      id:3,
      chinese:'新闻',
      meta: {
        title: 'Tin tức du học Trung Quốc mới nhất',
        keywords: 'Tin tức Học bổng Trung Quốc,Tin tức du học Trung Quốc',
        description: 'Trang thông tin học bổng du học Trung Quốc. Chia sẻ thông tin mới nhất, hữu ích nhất đến các bạn học sinh. ☏Hotline: 0984 133 380. Xem ngay!'
      },
      children:[
        { 
          path: "/news",
          label:'TIN TỨC',
          name: "news",
          isclick:false,
          id:3,
          chinese:'新闻',
          meta: {
            title: 'Tin tức du học Trung Quốc mới nhất',
            keywords: 'Tin tức Học bổng Trung Quốc,Tin tức du học Trung Quốc',
            description: 'Trang thông tin học bổng du học Trung Quốc. Chia sẻ thông tin mới nhất, hữu ích nhất đến các bạn học sinh. ☏Hotline: 0984 133 380. Xem ngay!'
          },
          component: () =>import( "@/views/news.vue"),
        },
          { 
            path: "/event",
            label:'SỰ KIỆN',
            name: "event",
            isclick:false,
            id:4,
            chinese:'新闻',
            meta: {
              title: 'Sự kiện - Hoạt động mới nhất của EIC Education',
              keywords: 'Sự kiện, hoạt động EIC Education',
              description: 'Trang thông tin sự kiện - hoạt động của công ty EIC Education'
            },
            component: () =>import( "@/views/event.vue"),
        },
    //     { 
    //       path: "/newsHanh",
    //       label:'Hành trang du học',
    //       name: "newsHanh",
    //       isclick:false,
    //       id:4,
    //       chinese:'攻略',
    //       meta: {
    //           title: ''
    //         },
    //       component: () =>import( "@/views/newsHanh.vue"),
    //   },{ 
    //     path: "/newsCon",
    //     label:'Con người, văn hóa',
    //     name: "newsCon",
    //     isclick:false,
    //     id:4,
    //     chinese:'文化',
    //     meta: {
    //         title: ''
    //       },
    //     component: () =>import( "@/views/newsCon.vue"),
    // },
      ]
  },
    {
      path: "/user",
      chinese:'故事',
      label:'VỀ CHÚNG TÔI',
      isclick:false,
      id:4,
      name: "user",
      children:[
        {
          path: "/partner",
          chinese:'伙伴',
          label:'ĐỐI TÁC CỦA CHÚNG TÔI',
          isclick:false,
          pId:4,
          name: "partner",
          meta: {
            title: 'Các đối tác của EIC Education',
            keywords: '',
            description: 'EIC Education là đối tác tin cậy của các trường đại học hàng đầu Trung Quốc và Việt Nam. '
          },
          component: () =>import( "@/views/partner.vue"),
        },
        {
          path: "/story",
          chinese:'故事',
          label:'CÂU CHUYỆN',
          isclick:false,
          pId:4,
          name: "story",
          meta: {
            title: 'Câu chuyện phát triển EIC Education',
            keywords: 'Công ty EIC Education',
            description: 'EIC Education - Công ty tư vấn học bổng du học Trung Quốc có 25 năm kinh nghiệm, 200 trường đối tác hàng đầu Trung Quốc đã giúp hơn 10.000 hồ sơ thành công du học Trung Quốc'
          },
          component: () =>import( "@/views/story.vue"),
        },
        {
          path: "/contact",
          chinese:'联系',
          label:'LIÊN HỆ',
          isclick:false,
          pId:4,
          name: "contact",
          meta: {
            title: 'Liên Hệ Với Chúng Tôi | Trung Tâm Tư Vấn Du Học Uy Tín Hàng đầu',
            keywords: '',
            description: 'Liên hệ với EIC Education để được tư vấn về thông tin du học Trung Quốc một cách chính xác và săn học bổng du học Trung Quốc.'
          },
          component: () =>import( "@/views/contact.vue"),
        },
      ]
    },
  ]
export const newspath = [
  {
    path: "/SchoolDetails/specialtyDetails",
    chinese:'专业详情',
    name: "specialtyDetails",
    label:'Du học và Xin học bổng',
    meta: {
        title: 'Du học và Xin học bổng'
      },
    component: () =>import( "@/views/SchoolDetails/specialtyDetails.vue"),
  },
  {
    path: "/SchoolDetails/schoolMajorDetails",
    chinese:'学校详情',
    name: "schoolMajorDetails",
    meta: {
        title: 'Du học và Xin học bổng'
      },
    component: () =>import( "@/views/SchoolDetails/schoolMajorDetails.vue"),
  },
  {
    path: "/NewsDetails/Newsone",
    chinese:'新闻详情1',
    name: "Newsone",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsone.vue"),
  },
  {
    path: "/NewsDetails/Newstwo",
    chinese:'新闻详情2',
    name: "Newstwo",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newstwo.vue"),
  },
  {
    path: "/NewsDetails/Newsthree",
    chinese:'新闻详情3',
    name: "Newsthree",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsthree.vue"),
  },
  {
    path: "/NewsDetails/Newsfore",
    chinese:'新闻详情4',
    name: "Newsfore",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsfore.vue"),
  },
  {
    path: "/NewsDetails/Newsfive",
    chinese:'新闻详情5',
    name: "Newsfive",
    meta: {
        title: 'Tư vấn du học Trung Quốc'
      },
    component: () =>import( "@/views/NewsDetails/newsfive.vue"),
  },
]