import { createRouter, createWebHistory ,createWebHashHistory} from "vue-router";
import { path,newspath } from '@/router/path'
import { clearform,routerPath } from '@/utlis/constJSON'
import { trafficStatistics } from '@/api/school'
import { reactive,ref } from 'vue';

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>import("@/views/home.vue"),
  },
  ...path,
  ...newspath,
];
const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes,
});
function generateUUID() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '越南来华网站';
  routerPath.value = to.path
  if(!window.localStorage.getItem("UV")){
    window.localStorage.setItem("UV",generateUUID() + generateUUID())
  }
  trafficStatistics('school',{
    url: window.location.href,
    uv: window.localStorage.getItem("UV"),
    pv: generateUUID() + generateUUID(),
    type: to.path,
  })
  clearform() 
    window.scrollTo({
      top: 0,
    })
  next();
});

export const goRouter = (str,query={})=>{
  router.push({
    path: `/${str}`,
    query,
  })
}

export default router;
