import { path } from '@/router/path'
import { createStore } from "vuex";

export default createStore({
  state: {
    path:path||[],
    mobileIsHeaderOpenNav:false,
    projectArr: [],
    newsArr: [],
    activityArr: [],
    hanhArr: [],
    conArr: [],
  },
  getters: {
    getterPath(state){
      return state.path
    },
    gettermobileIsHeaderOpenNav(state){
      return state.mobileIsHeaderOpenNav
    },
  },
  mutations: {
    changePath(state,path){
      state.path = path
    },
    changemobileIsHeaderOpenNav(state,mobileIsHeaderOpenNav){
      state.mobileIsHeaderOpenNav = mobileIsHeaderOpenNav
    },
    changeProjectList(state,mobileIsHeaderOpenNav){
      state.projectArr = mobileIsHeaderOpenNav
    },
    changeNewsList(state,data){
      state.newsArr = data
    },
    changeActivityList(state,data){
      state.activityArr = data
    },
    changeHanhList(state,data){
      state.hanhArr = data
    },
    changeConList(state,data){
      state.conArr = data
    },
  },
  actions: {
    updatePath(context,path){
      context.commit('changePath',path)
    },
    updatemobileIsHeaderOpenNav(context,mobileIsHeaderOpenNav){
      context.commit('changemobileIsHeaderOpenNav',mobileIsHeaderOpenNav)
    },
    updateProjectArr(context,data){
      context.commit('changeProjectList',data)
    },
    updateNewsArr(context,data){
      context.commit('changeNewsList',data)
    },
    updateActivityArr(context,data){
      context.commit('changeActivityList',data)
    },
    updateHanhArr(context,data){
      context.commit('changeHanhList',data)
    },
    updateConArr(context,data){
      context.commit('changeConList',data)
    }
  },
  modules: {},
});
