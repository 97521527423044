<template>
  <div v-show="state.loading">
    <div class="appPC" v-show="!state.isPC">
      <Header />
      <div class="view">
        <router-view />
      </div>
      <Footer></Footer>
      <!-- <Footer v-show="!state.isFooter"></Footer>
      <Footer2 v-show="state.isFooter"></Footer2> -->
    </div>
    <div v-show="state.isPC" class="appmobile">
      <MobileHeader @open="drawer = !drawer" />
      <div id="viewmobile" class="viewmobile">
        <div class="view">
          <el-drawer v-model="drawer" v-if="drawer" direction="ltr" :append-to-body="true" append-to="#viewmobile" :with-header="false">
            <el-menu
              active-text-color="#F5AA3B"
              class="el-menu-vertical-demo"
              :default-active="direct"
              text-color="#004163"
              @select="handleOpen"
            >
            <template v-for="item,index in arr" :key="index">
              <el-sub-menu v-if="item.children" :index="item.path">
                <template v-if="item.children" #title>
                  <span>{{ item.label }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item :index="index + '-' + item2.path" v-for="item2,index2 in item.children" :key="index2">{{ item2.label }}</el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
              <el-menu-item v-else :index="item.path">
                <span>{{ item.label }}</span>
              </el-menu-item>
            </template>
            </el-menu>
          </el-drawer>
          <router-view />
        </div>
      </div>
        <MobileFooter/>
    </div>
    <Suspension />
  </div>
 
</template>
<script setup name="app">
import {  computed, ref,onMounted,  nextTick, reactive,onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Header from '@/components/header'
import Footer from '@/components/footer'
import Footer2 from '@/components/footer2'
import { getProjectList } from '@/api/home/index'
import MobileFooter from '@/components/mobileFooter'
import MobileHeader from '@/components/mobileHeader'
import Suspension from '@/components/suspension.vue'
// 在你的 Vue 文件中或入口文件（如 main.js）中添加
import 'swiper/swiper-bundle.css';
const router = useRouter()
import { useStore } from "vuex";
const store = useStore()
let projectList = ref([])
let path = []
let arr = ref([])
getProjectList().then(res => {
  projectList.value = res.items;
  console.log(projectList.value);
  store.dispatch('updateProjectArr',res.items)
  path = computed(() => store.state.path);
  path.value.forEach((item,index) => {
    // if(item.path === '/serve'){
    //   projectList.value.forEach((it,ins) => {
    //     path.value[index].children[ins].label = it.name
    //     path.value[index].children[ins].path = '/cultivate' + it.id
    //     path.value[index].children[ins].id = it.id
    //   })
    // }
  })
  arr.value.push(...path.value)
})
let direct = ref('')
const handleOpen = (val,path) => {
  direct.value = val
  console.log(val,path);
  if(path.length === 2){
    console.log(path[1].slice(2,path[1].length));
    
    router.push({
      path: path[1].slice(2,path[1].length),
      query:{
        id: path[1].slice(2,path[1].length).split('=')[1]
      }
    })
  }else{
    router.push({
      path: path[0],
    })
  }
  drawer.value = false
}

const drawer = ref(false)
const state = reactive({
  loading:false,
  isPC:false,
  isFooter:false,
})
const handleResize = () => {
      // console.log(window.innerWidth,window.matchMedia('(orientation: landscape)').matches)
      state.isPC=!(window.matchMedia('(orientation: landscape)').matches)
};
const changeisFooter = ()=>{
  let path = window.location.pathname;
  state.isFooter = path.indexOf('contact') > -1
  state.isPC = document.body.clientWidth < 600
  state.loading = true
}
onMounted(()=>{
  changeisFooter()
  window.addEventListener('resize', handleResize);
})
onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    })
</script>


<style lang="scss">
:root{
  --el-color-primary: #004163 !important;
}
*{
  box-sizing: border-box;
  margin: 0;
}
html,body{
  margin: 0 auto;
  font-size: 20px;
  padding: 0;
  font-family: 'Alibaba Sans Viet, Alibaba Sans Viet';
  font-style: normal;
  text-transform: none;
  @font-face {
    font-family: 'Alibaba Sans Viet, Alibaba Sans Viet';
    src: url('./Fonts/AlibabaSansViet-Rg.ttf') format('truetype');
  }
  @font-face {
    font-family: 'AlibabaSansViet-Bd';
    src: url('./Fonts/AlibabaSansViet-Bd.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Barlow';
    src: url('./Fonts/Barlow-Bold.ttf') format('truetype');
  }
}
@media (max-width: 800px) {
  /* 针对小于 768px 宽度设备的样式 */
  html,body {
    font-size:4px;
  }
  .view{
    padding-top: 11.75rem !important;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  /* 针对 390px 到 1024px 宽度设备的样式 */
  html,body {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  /* 针对大于 1024px 宽度设备的样式 */
  html,body {
    font-size: 20px;
  }
}
.appPC{
  width: 100%;
}

.view{
  width: 100%;
  margin: 0 auto;
  padding-top: 8.54rem;
}
.appmobile{
  margin: 0 auto;
}
.viewmobile{
  overflow-x:auto ;
}
.viewmobile::-webkit-scrollbar {
  display: none;
}
.btn{
  cursor: pointer;
}
p{
  padding: 0;
  margin: 0;
}
#viewmobile{
  .el-overlay{
    z-index: 99 !important;
    top: 11.75rem;
    font-size: 3rem;
    color: #004163;
    .el-drawer{
      width: 90% !important;
      .el-drawer__body{
        padding: 0;
        .el-menu-item-group__title{
          display: none;
        }
        .is-active{
          background: #f7f9fa !important;
          position: relative;
        }
        .is-active::before{
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 4px;
          height: 14rem;
          background: #F5AA3B;
        }
        .el-menu-item-group{
          .is-active::before{
            width: 0;
          }
        }
      }
    }
  }
}
a{
  text-decoration: none;
  color: black;
  margin: 0;
  padding: 0;
}
a:hover{
  text-decoration-line: underline;
  margin: 0;
  padding: 0;
}
.el-carousel__button {
    width: 6px !important;
    height: 6px !important;
    background-color: #004163 !important;
    opacity: 1 !important;
}
.is-active .el-carousel__button {
    width: 30px !important;
    height: 6px !important;
    background-color: #F5AA3B !important; /* 将#333替换为你想要的激活指示器颜色 */
}
.el-message-box__title{
  text-align: center !important;
  margin-left: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #1A1A1A;
}
.el-message-box__message{
  margin: 0 auto !important;
}
.el-message-box__btns{
  justify-content: center !important;
  .el-button{
    width: 90px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #F5AA3B;
    border: none;
    margin-top: 30px;
  }
}
.el-message-box__container{
  font-weight: 700;
  font-size: 28px;
  color: #004163;
}
.el-button:hover{
  background: #F5AA3B !important;
}

.numicon{
  font-family: Barlow !important;
}
</style>
