import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
import * as ElIcon from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import NutUI from "@nutui/nutui";
// 采用按需加载时  此全局css样式，需要删除
import "@nutui/nutui/dist/style.css";
const app = createApp(App);
Object.keys(ElIcon).forEach((key) => {
  app.component(key, ElIcon[key])
})
app.use(store).use(router).use(ElementPlus).use(NutUI).mount("#app")