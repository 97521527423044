<template>
  <div class="footerlongbox">
    <div class="footer" id="footer">
    <div class="footerbox">
      <div class="left">
        <img src="@/assets/icon/icon.png" alt="">
        <div class="lt1">
          {{ jsonObj.footer.lt1}}
        </div>
        <div class="lt2">
          <div>
            <img src="@/assets/icon/positions.png" alt="">
            <span>{{ jsonObj.footer.lt2_1 }}</span>
          </div>
          <div>
            <img src="@/assets/icon/phones.png" alt="">
            <span>{{ jsonObj.footer.lt2_2 }}</span>
          </div>
          <div>
            <img src="@/assets/icon/email.png" alt="">
            <span>{{ jsonObj.footer.lt2_3 }}</span>
          </div>
        </div>
        <div class="lt3">
          <div>
            <img src="@/assets/icon/facebookfff.png" alt="" @click.stop="jumpUrl('https://www.facebook.com/eiceducationvn')">
          </div>
          <div>
            <img src="@/assets/icon/zalo.png" alt="" @click.stop="jumpUrl('https://zalo.me/0984133380')">
          </div>
          <div>
            <img src="@/assets/icon/email.png" alt="" @click.stop="jumpRouterchild('contact','user')">
          </div>
        </div>
    </div>
    <div class="cont">
      <div class="cont1" >
        {{ jsonObj.footer.cont1 }}
      </div>
      <div class="cont2" @click.stop="jumpRouterchild('story','user')">
        {{ jsonObj.footer.cont2_1  }}
      </div>
      <div class="cont2" @click="jumpRouterchild('cultivate1','serve',{id: 1})">
        {{ jsonObj.footer.cont2_2  }}
      </div>
      <div class="cont2" @click.stop="jumpRouterchild('schoolmajor','school')">
        {{ jsonObj.footer.cont2_3  }}
      </div>
      <div class="cont2" @click.stop="jumpRouterchild('specialty','school')">
        {{ jsonObj.footer.cont2_4  }}
      </div>
      <div class="cont2" @click="jumpRouterchild('scholarship','scholarship')">
        {{ jsonObj.footer.cont2_5  }}
      </div>
      <div class="cont2">
        <!-- {{ jsonObj.footer.cont2_6  }} -->
      </div>
    </div>
    <div class="right">
      <div class="ri">
        {{ jsonObj.footer.r1 }}
      </div>
      <el-form ref="formRef" :model="jsonObj.footerfrom" :rules="jsonObj.rules">
        <div class="fromdiv">
          <el-form-item prop="name">
            <el-input
              v-model="jsonObj.footerfrom.name"
              style="width: 21rem;height: 32px;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
              :placeholder="jsonObj.footer.nameplaceholder"
            />
          </el-form-item>
        </div>
        <div class="fromdiv">
          <el-form-item prop="age">
            <el-input
            v-model="jsonObj.footerfrom.age"
            style="width: 21rem;height: 32px;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            :placeholder="jsonObj.footer.birthdayplaceholder"
          />
          </el-form-item>
          
        </div>
        <div class="fromdiv phoneandEmil">
          <el-form-item prop="mobile">
            <el-input
            v-model="jsonObj.footerfrom.mobile"
            style="width: 10.1rem;height: 32px;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            :placeholder="jsonObj.footer.phoneplaceholder"
          />
          </el-form-item>
          
          <el-form-item prop="email">
            <el-input
            v-model="jsonObj.footerfrom.email"
            style="width: 10.1rem;height: 32px;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            :placeholder="jsonObj.footer.Emailplaceholder"
          />
          </el-form-item>
        </div>
        <div class="fromdiv learnandsend">
          <el-form-item prop="academicDegree">
            <el-select
            v-model="jsonObj.footerfrom.academicDegree"
            :placeholder="jsonObj.footer.learnplaceholder"
            style="width:15.45rem;height: 32px;background: rgba(255,255,255,0.05);border-radius: 0rem 0rem 0rem 0rem;"
            size="large"
            clearable 
          >
            <el-option :label="jsonObj.footer.learnOption1" :value="jsonObj.footer.learnOption1"></el-option>
            <el-option :label="jsonObj.footer.learnOption2" :value="jsonObj.footer.learnOption2"></el-option>
            <el-option :label="jsonObj.footer.learnOption3" :value="jsonObj.footer.learnOption3"></el-option>
            <el-option :label="jsonObj.footer.learnOption4" :value="jsonObj.footer.learnOption4"></el-option>
          </el-select>
          </el-form-item>
          <el-form-item>
            <div class="clicksend">
              <el-button class="click" @click="send">{{ jsonObj.footer.send }}</el-button>
            </div>
          </el-form-item>
        </div>
      </el-form>
      
    </div>
    </div>
    <div class="fenge"></div>
    <div class="tips">{{jsonObj.footer.tips }}</div>
  </div>
  </div>
  
  
</template>

<script setup name="footer">
  import { reactive,ref,computed } from 'vue';
import {jsonObj,imgUrl,submitForm} from '@/utlis/constJSON.js'
import {goRouter} from '@/router/index.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from "vuex";
const store = useStore()
//新闻列表
let projectList = computed(() => store.state.projectArr);

const formRef = ref()
  const state = reactive({
    sendOK:false,
  })
  const send = ()=>{
    console.log(jsonObj.footerfrom)
    formRef.value?.validate(async (valid) => {
        if (!valid) return false;
        await submitForm()
    })
  }
  const jumpUrl = (url)=>{
    window.open(url, '_blank');
  }
const jumpRouterchild = (str,fatStr,query={})=>{
  jsonObj.headerNav = fatStr
  goRouter(str,query)
}
</script>
<style lang="scss" scoped>
.footerlongbox{
  width: 100%;
  background-color: #004163;
  border-bottom: 5px solid #F5AA3B;
}
.footer{
  width: 60rem;
  margin: 0 auto;
  color: #fff;
  padding: 5rem 0 2.6rem;
  .footerbox{
  display: flex;
  justify-content: left;
  .left{
    width: 22.75rem;
    text-align: left;
    font-size: 0.9rem;
    img{
      width: 10.05rem;
      padding-bottom: 0.75rem;
    }
    .lt1{
    }
    .lt2{
      padding-top:2.75rem;
      div{
        padding-top: 0.5rem;
        display: flex;
        justify-content: left;
        align-items: center;
        img{
          width: 32px;
          margin-right: 10px;
          padding-bottom: 0;
        }
        span{
          opacity: 0.8;
          color: #FFFFFF;
          font-size: 0.9rem;
        }
      }
    }
    .lt3{
      padding-top: 1.4rem;
      display: flex;
      justify-content: left;
      div{
        padding-right: 1.5rem;
        img{
          width: 2.1rem;
          cursor: pointer;
        }
      }
    }
  }
  .cont{
    width: 16.5rem;
    padding-top: 2.6rem;
    font-size: 1rem;
    color: #FFFFFF;
    .cont1{
      font-weight: 600;
      padding-bottom: 1.75rem;
    }
    .cont2{
      font-size: 0.9rem;
      padding-bottom: 1rem;
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .right{
    width: 21rem;
    padding-top: 2.6rem;
    .ri{
      font-size: 1rem;
      padding-bottom: 1.5rem;
      line-height: 24px;
    }
    .fromdiv{
      padding-bottom: 6px;
    }
    :deep(.el-form-item__error){
      color: #ea0000;
      font-size: 18px;
      left: 11px;
      top: 5px;
      background: #fff;
    }
    :deep(.learnandsend .el-form-item__error){
      top: 16px;
    }
    .phoneandEmil{
      display: flex;
      justify-content: space-between;
    }
    .learnandsend{
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      .clicksend{
        transform: translateY(0.2rem);
      }
      .click{
        width: 4.8rem;
        height: 2.1rem;
        background: #F5AA3B;
        border: 1px solid #AAAAAA;
        font-weight: 500;
      }
    }
  }
  
  }
  .fenge{
    width: 35rem;
    border-top: 0.03rem solid #F5AA3B;
  }
  .tips{
      padding: 1rem 0 0 ;
      font-weight: 300;
      font-size: 0.7rem !important;
      color: #FFFFFF;
      opacity: 0.8;
    }
}

</style>