import { computed, ref, reactive,watch ,onMounted} from 'vue';
// let VITE_SCHOOL_URL = 'https://api-word-staging.eiceducation.com.cn'\
let VITE_SCHOOL_URL
if(window.location.host === 'eiceducation.vn'){
    VITE_SCHOOL_URL = ' https://api.eiceducation.vn'
}else{
    VITE_SCHOOL_URL = 'https://vcc-api-test.eiceducation.com.cn'
}

export const getUrl = (type)=>{
    let url=''
    switch (type) {
        case 'school':
            url = VITE_SCHOOL_URL
            break;
        default:
            break;
    }
    return url
}