<template>
  <div class="suspension">
    <div class="list">
      <img class="img" @mouseenter="img1 = true" @click="externalJump('https://zalo.me/0984133380')" @mouseleave="img1 = false" :src="!img1 ? zalo : zaloOver" alt="">
      <img class="img-m" @click="externalJump('https://zalo.me/0984133380')" src="@/assets/zalo-m.png" alt="">
    </div>
    <div class="list">
      <img class="img" @mouseenter="img2 = true" @click="externalJump('https://m.me/eiceducationvn')" @mouseleave="img2 = false" :src="!img2 ? facebook : facebookOver" alt="">
      <img class="img-m" @click="externalJump('https://m.me/eiceducationvn')" src="@/assets/info-m.png" alt="">
    </div>
    <div class="list">
      <img class="img" @mouseenter="img3 = true" @mouseleave="img3 = false" :src="!img3 ? phone : phoneOver" @click="pcPhone" alt="">
      <a href="tel:0984133380">
        <img class="img-m" src="@/assets/tell-m.png" alt="">
      </a>
    </div>
  </div>
</template>
<script setup name="suspension">
import { computed, ref, onMounted, nextTick, watch, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus'

import zalo from '@/assets/zalo.png'
import facebook from '@/assets/facebook.png'
import phone from '@/assets/phone.png'
import zaloOver from '@/assets/zalo-over.png'
import facebookOver from '@/assets/facebook-over.png'
import phoneOver from '@/assets/phone-over.png'

const router = useRouter()

const img1 = ref(false)
const img2 = ref(false)
const img3 = ref(false)

const externalJump = (url) => {
  window.open(url,'_blank')
}
const pcPhone = () => {
  ElMessageBox.alert('0984133380', 'Thông tin liên hệ:', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: 'OK',
  })
}

</script>
<style lang="scss" scoped>
.suspension{
  position: fixed;
  bottom: 200px;
  width: 100px;
  right: 30px;
  z-index: 1000;
  .list{
    width: 100px;
    height: 100px;
    cursor: pointer;
    .img{
      display: block;
      width: 100px;
      height: 100px;
    }
    .img-m{
      display: none;
    }
  }
}
.kefu {
  width: 5rem;
  height: 5rem;
  position: fixed;
  right: 0;
  top: 35rem;
  z-index: 10000;
}

@media (max-width: 800px) {

  /* 针对小于 768px 宽度设备的样式 */
  .kefu {
    width: 10rem;
    height: 10rem;
    top: 120rem;
  }
  .suspension{
    right: 1rem;
    width: 13rem;
    .list{
      width: 13rem;
      height: 13rem;
      border-radius: 50%;
      overflow: hidden;
      .img{
        display: none;
      }
      .img-m{
        display: block;
        width: 13rem;
      }
    }
  }
}
</style>