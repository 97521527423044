import request from '@/api/index'
import axios from 'axios'
import {getUrl} from '../case'
let VITE_SCHOOL_URL
if(window.location.host === 'eiceducation.vn'){
    VITE_SCHOOL_URL = ' https://api.eiceducation.vn'
}else{
    VITE_SCHOOL_URL = 'https://vcc-api-test.eiceducation.com.cn'
}

export const fromsubmit = (type,data)=>{
    let url = getUrl(type)
    return axios.post(`${VITE_SCHOOL_URL}/api/lhFrom`,data)
}

