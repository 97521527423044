import { computed, ref, reactive,watch ,onMounted} from 'vue';
import { useRoute } from 'vue-router';
import {getJson} from '@/api/home/index'
import { rules } from 'eslint-config-prettier';
import {fromsubmit} from '@/api/submitfrom'
export const imgUrl = 'https://lximg.eiceducation.com.cn/img/'
export const jsonObj = reactive({
    headerNav:"",
    headerinput:'',
    headercountryOptions:[
        {
            value:3,
            label:'中文'
        },
        {
            value:1,
            label:'Việt nam'
        },
        {
            value:2,
            label:'English'
        },
    ],
    header:{
        input:'',
        inputPlaceholder:'tìm kiếm',
        home:"TRANG CHỦ",
        school:'TÌM TRƯỜNG, NGÀNH HỌC',
        specialty:'TÌM NGÀNH',
        schoolmajor:'TÌM TRƯỜNG',
        serve:"DỊCH VỤ",
        cultivate:'HỌC TIẾNG TRUNG',
        studyAbroadLong:'DU HỌC DÀI HẠN',
        studyAbroadShort:'DU HỌC NGẮN HẠN',
        jiangjin:"HỌC BỔNG",
        user:"VỀ CHÚNG TÔI",
        partner:"ĐỐI TÁC CỦA CHÚNG TÔI",
        story:"CÂU CHUYỆN",
        contact:"LIÊN HỆ",
        btn:"Đăng ký ngay",
        news: 'TIN TỨC, HOẠT ĐỘNG',
        newsTin: 'TIN TỨC',
        newsSu: 'SỰ KIỆN',
        newsHanh: 'Hành trang du học',
        newsCon: 'Con người, văn hóa',
    },
    footerfrom:{
        name:'',
        age:"",
        mobile:"",
        email:"",
        academicDegree:"",
    },
    rules:{
        name: [{ required: true, message: 'Tên', trigger: 'blur' }],
        age: [{ required: true, message: 'Năm sinh', trigger: 'blur' }],
        mobile: [{ required: true, message: 'Số điện thoại', trigger: 'blur' }],
        email: [{ required: true, message: 'email', trigger: 'blur' }],
        academicDegree: [{ required: true, message: 'Bậc học quan tâm', trigger: 'blur' }],
    },
    footer:{
        lt1:'EIC EDUCATION VIETNAM',
        lt2_1:"110 Trần Vỹ, Mai Dịch, Cầu Giấy, Hà Nội",
        lt2_2:"0984133380",
        lt2_3:"eiceducationvn@gmail.com",
        cont1:'Đường dẫn nhanh',
        cont2_1:'Về chúng tôi',
        cont2_2:'Dịch vụ',
        cont2_3:'Tìm trường',
        cont2_4:'Tìm ngành học',
        cont2_5:'Danh sách học bổng',
        cont2_6:'TIN TỨC du học',
        r1:'Để lại thông tin của bạn ngay để được tư vấn cùng các chuyên gia!',
        r2:'LIÊN HỆ',
        nameplaceholder:'Tên',
        birthdayplaceholder:'Năm sinh',
        phoneplaceholder:'Số điện thoại',
        Emailplaceholder:'email',
        learnplaceholder:'Bậc học quan tâm',
        learnOption1:"Học ngôn ngữ/ngắn hạn",
        learnOption2:"Học đại học/cử nhân",
        learnOption3:"Học thạc sĩ",
        learnOption4:"Học tiến sĩ",
        send:'Gửi',
        tips:"Copyright © 2018 The Project by Ant Motion. All Rights Reserved"
    },
    home:{
        bannerTitle: 'Chinh phục giấc mơ du học Trung Quốc cùng tổ chức giáo dục hàng đầu Trung Hoa',//banner的datitle
        bannerbtn:'Về EIC',//banner的跳转按钮
        left:'Xem cuộn',//banner左侧的文字
        box1title:'MỤC TIÊU CỦA BẠN LÀ GÌ?',
        box1L1:"Học tiếng Trung",
        box1L2:"Du học Đại học",
        box1L3:"Du học ngắn hạn",
        box2title:"THÀNH CÔNG CỦA CHÚNG TÔI",
        box2subTitle:"Những con số biết nói trong quá trình phát triển của EIC",
        box2count1:'Năm hình thành và phát triển',
        box2count2:'Học sinh đã thành công cùng chúng tôi',
        box2count3:'Trường Đại học Trung Quốc là đối tác của chúng tôi',
        box2count4:'Văn phòng tại Trung Quốc, 9 văn phòng tại Việt Nam, Úc, Anh, Canada',
        box2count5:'Học sinh du học Trung Quốc mỗi năm',
        box3title:"LÀ ĐẠI DIỆN TUYỂN SINH CHÍNH THỨC CỦA",
        box3Swipertag:'Xếp hạng', //黄色的
        box3SwipersubTitle1:'Địa chỉ', //下面把的副标题1
        box3SwipersubTitle2:'Học bổng',//下面把的副标题2
        box3Swiper1title:'Đại học Công nghiệp Bắc Kinh',
        box3Swiper1value1:'Bắc Kinh',
        box3Swiper1value2:'Đại học: Miễn học phí năm đầu',
        box3Swiper1value2_1:'Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng',
        box3Swiper2title:'Đại học Bách Khoa Bắc Kinh (BIT) ',
        box3Swiper2value1:'Bắc Kinh',
        box3Swiper2value2:'Đại học: Lên đến 100% học phí, 100% ký túc xá và trợ cấp 2.500 tệ/tháng',
        box3Swiper2value2_1:"Thạc sĩ: Lên đến 100% học phí,100% ký túc xá và trợ cấp 2.500 tệ/tháng",
        box3Swiper3title:'Đại học Công nghiệp Hà Nam',
        box3Swiper3value1:'Trịnh Châu, Hà Nam',
        box3Swiper3value2:'Đại học: 100% học phí và ký túc xá',
        box3Swiper3value2_1:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box3Swiper4title:'Đại học Giao Thông Tây An',
        box3Swiper4value1:'Tây An, Thiểm Tây',
        box3Swiper4value2:'Đại học: Lên đến 100% học phí và trợ cấp sinh hoạt trong 10 tháng ',
        box3Swiper4value2_1:'Thạc sĩ: 100% học phí, ký túc xá, và trợ cấp hàng tháng',
        box3Swiper5title:'Đại học Ôn Châu',
        box3Swiper5value1:'Ôn Châu, Chiết Giang',
        box3Swiper5value2:'Đại học: Lên đến 20.000 tệ/năm',
        box3Swiper5value2_1:'Thạc sĩ: 100% học phí, 100% ký túc xá và trợ cấp hàng tháng',
        box3Swiper6title:'Đại học Công nghiệp Tây Bắc',
        box3Swiper6value1:'Tây An, Thiểm Tây',
        box3Swiper6value2:'Đại học: Miễn 100% học phí, ký túc xá và trợ cấp hàng tháng',
        box3Swiper6value2_1:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box3Swiper7title:'Đại học Tài chính Kinh tế Trung Ương',
        box3Swiper7value1:'Tây An, Thiểm Tây',
        box3Swiper7value2:'Đại học: Lên đến 100% học phí và trợ cấp sinh hoạt trong 10 tháng ',
        box3Swiper7value2_1:"Thạc sĩ: 100% học phí, ký túc xá, và trợ cấp hàng tháng",
        box3Swiper8title:'Đại học Chiết Giang',
        box3Swiper8value1:'Hàng Châu, Chiết Giang',
        box3Swiper8value2:'Đại học: 20.000 tệ cho một năm  ',
        box3Swiper8value2_1:"Thạc sĩ: Lên đến 100% học phí, 100% ký túc xá và trợ cấp hàng tháng",
        box3Swiper9title:'Đại học Sư Phạm Quảng Tây',
        box3Swiper9value1:'Quế Lâm, Quảng Tây',
        box3Swiper9value2:'Đại học: Miễn 33% học phí ',
        box3Swiper9value2_1:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box3Swiper10title:'Học viện Nghệ Thuật Nam Kinh',
        box3Swiper10tabValue:'TOP 9 Art School',
        box3Swiper10value1:'Nam Kinh, Giang Tô',
        box3Swiper10value2:'Đại học: Miễn 100% học phí, ký túc xá và trợ cấp hàng tháng ',
        box3Swiper10value2_1:" Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box4_1ltitle:'TÌM ĐÍCH ĐẾN CHO TƯƠNG LAI CÙNG CHÚNG TÔI',
        box4_1lsubTitle:"Tìm trường, ngành và bắt đầu hành trình du học Trung Quốc ngay!",
        box4_1lbtn1:"Tìm trường",
        box4_1lbtn2:'Tìm khóa học',
        box4_2title:'TIN TỨC',
        more: 'Tất cả',
        box4_2ltitle:"KHAI TRƯƠNG CÔNG TY EIC EDUCATION VIỆT NAM",
        box4_2lvalue:"Ngày 28 tháng 11 năm 2023, Công ty EIC Education Việt Nam đã chính thức thành lập văn phòng tại Hà Nội, Việt Nam. Thừa hưởng các phương pháp giá",
        box4_2rtitle:"EIC EDUCATION TỔ CHỨC DIỄN ĐÀN GIÁO DỤC QUỐC TẾ",
        box4_2value:"Vào ngày 27 tháng 10 năm 2023, diễn đàn Sáng kiến bồi dưỡng nhân tài học tập ở Trung Quốc thời đại mới - Góc nhìn từ tổ chức và doanh nghiệp...",
        box4_3rtitle:"7 LÝ DO DU HỌC TRUNG QUỐC: NỀN GIÁO DỤC VÀ KINH TẾ",
        box4_3value:"Vì sao du học Trung Quốc ngày càng trở nên phổ biến, đặc biệt là với học sinh Việt Nam chúng ta? Trung Quốc, với sự phát triển vượt bậc về kinh tế ...",
        box4_4rtitle:"Hỏi & Đáp: Du học Trung Quốc - Tất tần tật về bài kiểm tra trình độ đầu vào mới",
        box4_4value:"Tính đến ngày 22 tháng 5 năm nay, chính thức có thông báo về việc du học sinh sẽ phải làm bài kiểm tra trình độ học vấn đầu vào từ các trường đại ...",
        box4_5rtitle:"Học tiếng Trung cho người mới bắt đầu nên bắt đầu từ đâu?",
        box4_5value:"Học tiếng Trung cho người mới bắt đầu nên bắt đầu từ đâu? Đây là một là một vấn đề luôn thường trực trong những người có nguyện vọng theo ...",
        box4_3title:"SỰ KIỆN SẮP DIỄN RA", 
        box4_3value1:"Khai trương CLB Hội Quán Hoa Ngữ",
        box4_3value2:"Hội Quán Hoa Ngữ - Đón Tết Đoan Ngọ",
        box4_3value3:"Gặp gỡ trường Đại học Công nghiệp Hà Nam",
        // box4_3value4:"SỰ KIỆN Baba Baba",
        box4_4title:'HÌNH ẢNH HOẠT ĐỘNG',
        box5title:'Kết nối với chúng tôi',
        box5subTitle:'Theo dõi các kênh thông tin của EIC để cập nhật TIN TỨC du học nhanh và chính xác nhất!',
        box5btn1title:'Cộng đồng học sinh',
        box5btn1subTitle:"Tham gia vào cộng đồng du học sinh Trung Quốc của chúng tôi",
        box5btn2title:'Fanpage EIC Education Vietnam',
        box5btn2subTitle:"Theo dõi những SỰ KIỆN, TIN TỨC mới nhất của chúng tôi",
        box5btn3title:'Liên hệ tư vấn',
        box5btn3subTitle:"Liên hệ với chúng tôi để nhận tư vấn từ các chuyên gia",
    } ,
    cultivate:{
        title:'CHƯƠNG TRÌNH ĐÀO TẠO TIẾNG TRUNG',// 该页面大标题
        subTitle1:'Đa dạng trình độ, mục tiêu',//该页面小标题
        centent1:'Nhận thức rõ nhu cầu học ngôn ngữ đặc biệt của sinh viên quốc tế, EIC tin tưởng và theo đuổi triết lý "Giảng dạy chuyên sâu, thực hành thường xuyên và từng bước một". Kết hợp với kinh nghiệm nghiên cứu hàng thập kỷ các bài kiểm tra HSK, chúng tôi đã phát triển độc quyền chuỗi khóa học theo các trình độ bao gồm HSK0-3, HSK3-4 và HSK4-5, các lớp nâng cao tiếng Trung HSK5-6 và các khóa học cấp tốc trước kỳ thi cho mọi cấp độ.',
        subtitle2:'Đội ngũ giáo viên bản địa chuyên nghiêp',
        centent2:'Đội ngũ giáo viên của EIC Việt Nam bao gồm các giáo viên Trung Quốc bản địa, với kinh nghiệm nhiều năm trong quá trình đào tạo tiếng Trung từ giao tiếp đến học tập cho người nước ngoài. Qua đó, các học viên sẽ có thể trải nghiệm chân thực nhất chuyên môn của giáo viên bản địa trong "lớp học tiếng mẹ đẻ".',
        subtitle3:"Chương trình và phương pháp giảng dạy khoa học, bài bản",
        centent3:'Các khóa học được thiết kế dựa trên tài liệu giảng dạy tiếng Trung từ các trường đại học hàng đầu, hình thành hệ thống kiến ​​thức đầy đủ, khoa học, xây dựng lộ trình học tập rõ ràng, đầy đủ cho các học viên. Chúng tôi áp dụng mô hình lớp học kép gồm giáo viên + trợ giảng, tích hợp hoàn hảo việc “học” trong lớp và “học” ngoài lớp, đảm bảo rằng học viên được học một cách có hệ thống và củng cố nền tảng ngôn ngữ của mình. Ngoài ra, các lớp học còn có đầy đủ các bài thi thử cho phép học viên hiểu sâu các tình thế trong phòng thi thật, Từ rèn luyện kiến thức nền tảng, cốt lõi cho đến ôn luyện thi trước kỳ thi, EIC Việt Nam sẽ đồng hành cùng bạn trong suốt quá trình và giúp bạn đạt được mục tiêu tiếng Trung của mình.',
    },
    studyAbroadLong:{
        title:'CHƯƠNG TRÌNH DU HỌC ĐẠI HỌC',
        subTitle1:'Đa dạng cho mọi mục tiêu',
        centent1:'EIC Việt Nam mang sứ mệnh chắp cánh cho ước mơ du học Trung Quốc và chinh phục các bậc học từ cử nhân, thạc sĩ đến tiến sĩ của các bạn trẻ Việt Nam. Để làm được điều này, chúng tôi phối hợp chặt chẽ với hàng trăm trường đại học hàng đầu Trung Quốc, cùng các chương trình hợp tác chuyên sâu với hơn 50 trường bao gồm từ các đại học tổng hợp đến các cao đẳng chuyên nghiệp. Vì vậy, dù mục tiêu của các bạn học sinh, sinh viên nằm ở các ngành khoa học, kỹ thuật tại các trường dự án 985 hoặc 211 của Trung Quốc, hay là tìm kiếm các cơ hội học tập ứng dụng thực tiễn hơn ở các trường cao đẳng nghề, EIC đều có thể mang đến những sự hỗ trợ tận tình nhất cho mỗi bạn.',
        subtitle2:'Học bổng hào phóng',
        centent2:'Thấu hiểu nhu cầu của học sinh, sinh viên, EIC Việt Nam tư vấn và cung cấp nhiều phần học bổng du học từ các nguồn quốc gia, chính phủ, đến các học bổng cấp tỉnh, cấp thành phố, các gói học bổng riêng của các trường. Các học bổng này bao gồm các khoản miễn và giảm trừ từ học phí đến chi phí sinh hoạt, là sự hỗ trợ lớn lao cho các bạn sinh viên tương lai trên con đường học tập của mình.',
        subtitle3:"Chương trình phong phú",
        centent3:'Ngoài các chương trình học và chuyên ngành thông thường, EIC đã và đang triển khai các dự án phối hợp trực tiếp với các trường đại học Trung Quốc, cung cấp các chương trình lấy bằng đặc biệt như mô hình 1+3, 1+4, dự án “China+” và các chương trình độc đáo, giúp các bạn học sinh, sinh viên có nhiều lựa chọn hơn trong kế hoạch học tập của mình.',
    },
    studyAbroadShort:{
        title:'CHƯƠNG TRÌNH DU HỌC NGẮN HẠN',
        centent:"Bên cạnh các chương trình du học cho các bậc học cử nhân, thạc sĩ, tiến sĩ, EIC Việt Nam còn tổ chức và cung cấp các chương trình du học trải nghiệm ngắn hạn cho các bạn học sinh, sinh viên Việt Nam.",
        subTitle1:'Chương trình du học ngoại ngữ 1 năm',
        centent1:'Chương trình du học ngoại ngữ 1 năm hướng đến việc tạo ra cầu nối hiện thực hóa giấc mơ du học Trung Quốc cho các bạn học sinh, sinh viên chưa có đủ nền tảng tiếng Trung. Chương trình liên kết với các trường đại học hàng đầu Trung Quốc cùng các gói học bổng đa dạng giúp tối ưu hóa các lựa chọn của các bạn học sinh, sinh viên.',
        subtitle2:'Chương trình trao đổi 1 năm học bổng giáo viên Hán ngữ quốc tế',
        centent2:'Chương trình trao đổi ngắn hạn Học bổng Giáo viên Tiếng Trung Quốc tế dành cho những học sinh, sinh viên đã có nền tảng tiếng Trung nhất định. Các bạn bên cạnh cơ hội nhận được học bổng còn có thể nâng cao hơn nữa trình độ tiếng Trung và được tìm hiểu cũng như trải nghiệm bề dày lịch sử và sự phong phú trong văn hóa Trung Hoa.',
        subtitle3:"Các chương trình trại hè, trại đông",
        centent3:'Ngoài ra, EIC Việt Nam cũng đem đến các dự án tham quan học tập mang tính giáo dục và trải nghiệm, cho phép các bạn học sinh, sinh viên tìm hiểu sâu và trải nghiệm thực tế các tiêu chuẩn giáo dục và sức hấp dẫn của các đại học danh tiếng nhất Trung Quốc. Bên cạnh đó, các học viên sẽ được tiếp xúc gần gũi với Trung Quốc và trải nghiệm sâu sự độc đáo của văn hóa Trung Quốc trong quá trình du lịch, khám phá các địa danh, vùng miền. Những dự án này được thiết kế để đáp ứng nhu cầu riêng của từng học sinh, sinh viên, giúp các bạn học hỏi được nhiều điều hơn từ hành trình học tập tại Trung Quốc.',
    },
    partner:{
        title:'ĐỐI TÁC CỦA CHÚNG TÔI',
        box1title:'Đối tác của chúng tôi',
        box1value:'Được đánh giá là “Top 10 Thương hiệu giáo dục danh tiếng nhất Trung Quốc”, EIC Education đã và đang hợp tác trực tiếp với hơn 200 trường Đại học danh tiếng nhất Trung Quốc trên đa phương diện giáo dục. Qua những sự hợp tác này, chúng tôi mong muốn đem đến những thông tin trung thực và chính xác một cách kịp thời nhất đến các học viên của EIC.',
        box1btn:'Tìm trường',
        box2title:'Trường đối tác tại Trung Quốc',
        box2mapc21title:'Khu vực Tây Bắc',
        box2mapc21value:'Bao gồm các tỉnh thành: Ninh Hạ, Tân Cương, Thanh Hải, Thiểm Tây, Cam Túc',
        box2mapc28title:'Khu vực Tây Nam',
        box2mapc28value:'Bao gồm các tỉnh thành: Tứ Xuyên, Vân Nam, Quý Châu, Tây Tạng, Trùng Khánh',
        box2mapc29title:'Khu vực phía Nam',
        box2mapc29value:'Bao gồm các tỉnh thành: Quảng Đông, Quảng Tây, Hải Nam',
        box2mapc31title:'Khu vực Đông Bắc',
        box2mapc31value:' Bao gồm các tỉnh thành: Liêu Ninh, Cát Lâm, Hắc Long Giang',
        box2mapc37title:'Khu vực phía Bắc',
        box2mapc37value:'Bao gồm các tỉnh thành: Bắc Kinh, Thiên Tân, Hà Bắc, Sơn Tây, Nội Mông Cổ',
        box2mapc39title:'Khu vực Hoa Trung',
        box2mapc39value:'Bao gồm các tỉnh thành: Hồ Bắc, Hồ Nam, Hà Nam, Giang Tây',
        box2mapc76title:'Khu vực phía Đông',
        box2mapc76value:'Bao gồm các tỉnh thành: Sơn Đông, Giang Tô, An Huy, Đài Loan, Chiết Giang, Phúc Kiến, Thượng Hải',
        box3title:'Hoạt động của chúng tôi',
        box3subTitle:'EIC Education hướng đến việc làm cầu nối liên minh giữa các cơ sở giáo dục hàng đầu Trung Quốc và thế giới, tạo điều kiện và thúc đẩy các hoạt động hợp tác trong nhiều dự án giáo dục khác nhau, giúp nâng cao chất lượng giảng dạy và tên tuổi của các trường, cũng như giúp học sinh sinh viên toàn cầu được có những cơ hội tiếp xúc trực tiếp với đại diện của các trường.',
        box3tab1:'Gặp gỡ, kết nối các trường',
        box3tab2:'Triển lãm du học độc quyền',
        box3tab3:'Ngày hội tuyển sinh',
        box3tab4:'Liên kết giáo dục',
        box3tab1centen:'EIC Education kết nối và tổ chức các buổi gặp gỡ, làm quen giữa các trường đại học Trung Quốc và quốc tế, tổ chức các buổi đào tạo để hiểu rõ văn hóa, môi trường, phong tục nhằm hướng đến các cơ hội hợp tác giáo dục lâu dài',
        box3tab2centen:'EIC Education thường xuyên tổ chức những SỰ KIỆN triển lãm về giáo dục và du học, tạo không gian để đại diện các trường đại học trực tiếp tiếp cận và chia sẻ những thông tin chân thực và bổ ích nhất tới các bạn học viên',
        box3tab3centen:'EIC Education kết hợp các bộ phận tuyển sinh của các trường đại học với các nguồn học sinh để tổ chức những SỰ KIỆN tuyển sinh trực tiếp và trực tuyến, giúp học sinh có cơ hội gặp gỡ cũng như trực tiếp đánh giá hồ sơ của bản thân so với tiêu chí của các trường',
        box3tab4centen:'Với mạng lưới liên kết hơn 3000 trường đại học hàng đầu thế giới, EIC Education trực tiếp hỗ trợ trong quá trình thành lập và vận hành các chương trình hợp tác liên kết giáo dục (các khóa học dự bị, khóa học tăng tốc 3+2,...)  giữa các trường đại học Trung Quốc và đại học tại Mỹ, Anh, Úc, New Zealand, Canada, và nhiều quốc gia khác',
    },
    story:{
        title:'CÂU CHUYỆN CỦA CHÚNG TÔI',
        box1title:'Giới thiệu về EIC Education',
        box1centent1:'Thành lập vào năm 2000, EIC Education, hay còn gọi là Tập đoàn Giáo dục Khải Đức, là một trong những tổ chức giáo dục quốc tế hàng đầu đến từ chính Trung Hoa đại lục. Qua hơn hai thập kỷ xây dựng và phát triển, chúng tôi hiện sở hữu đội ngũ với hơn 3000 nhân viên tại gần 40 thành phố tại Trung Quốc và 9 thành phố khác toàn cầu với tổng cộng 72 văn phòng và chi nhánh, giúp đỡ hàng trăm nghìn học sinh, sinh viên hiện thực hóa mục tiêu du học của mình. ',
        box1centent1_1:'EIC Education tự hào vì là một trong những tổ chức tiên phong ưu tiên việc cung cấp một dịch vụ giáo dục toàn diện, chăm sóc tổng thể nhu cầu của từng học viên theo mỗi thời điểm, giai đoạn trên con đường sự nghiệp giáo dục. Để làm được điều này, chúng tôi cung cấp dịch vụ tập trung vào tất cả các khía cạnh quan trọng về học thuật (điểm số, chứng chỉ), con người (ngoại khóa, kỹ năng), và định hướng giáo dục du học trước, trong và sau quá trình nộp hồ sơ.',
        box1centent2:"Vào năm 2023, EIC Education Vietnam chính thức được thành lập với mục tiêu làm cầu nối giữa các học sinh, sinh viên Việt Nam với nền giáo dục của Trung Quốc và thế giới. EIC Education Vietnam cam kết trở thành một tổ chức giáo dục quốc tế đáng tin cậy và uy tín hàng đầu, chắp cánh cho ước mơ chạm đến kiến thức cho thế hệ trẻ Việt Nam.",
        box2title:'Quá trình phát triển',
        box2timet2000:'Thành lập Công ty TNHH Quảng Đông EIC Education Service tại Quảng Châu',
        box2timet2002:'Thành lập Công ty TNHH Quảng Đông EIC Education Service tại Quảng Châu',
        box2timet2003:'Thành lập trung tâm luyện thi EIC Test Preparation đầu tiên tại Quảng Châu và Tế Nam',
        box2timet2005:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2006:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2007:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2008:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2009:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2010:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2011:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2012:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2013:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2014:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2015:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2016:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2017:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2018:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2019:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2020:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2021:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2022:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2023:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box2timet2024:'Đánh dấu bước phát triển toàn quốc tại Trung Quốc, phát triển ra khỏi khu vực phía Nam Trung Hoa với các chi nhánh tại Tế Nam và Vũ Hán',
        box3title:'Văn hóa EIC',
        box3arr1title:"Tầm nhìn",
        box3arr1subTitle:"Trở thành Tổ chức giáo dục quốc tế đáng tin cậy hàng đầu",
        box3arr2title:"Sứ mệnh",
        box3arr2subTitle:"Cung cấp các giải pháp tư vấn và hoạch định giáo dục toàn diện, giúp học viên hoàn thiện về cả học thức và con người qua những mô hình giáo dục quốc tế chất lượng cao",
        box3arr3title:"Giá trị cốt lõi",
        box3arr3subTitle:"I CARE Care for Customers, Colleagues, and Yourself Tận tụy Tận tâm vì khách hàng, đồng nghiệp, và bản thân",
        box3arr4title:"Khẩu hiệu",
        box3arr4subTitle:"Teaching Lifelong Success",
        btn3:'Liên hệ ngay!'
    },
    contactInput:"",
    contact:{
        title:'LIÊN HỆ',
        box1_1Ltitle:'VĂN PHÒNG VIỆT NAM',
        box1_1Lposition:"110 Trần Vỹ, Mai Dịch, Cầu Giấy, Hà Nội",
        box1_1Lphone:'0984133380',
        box1_1Lemil:'eiceducationvn@gmail.com',
        box1_1R:'EIC EDUCATION VIETNAM',
        box2title:'HỆ THỐNG VĂN PHÒNG TOÀN CẦU',
        box2inputplaceholder:'Địa chỉ tìm kiếm' ,
        box2tabArr1:'Trung Quốc',
        box2tabArr2:'Australia',
        box2tabArr3:'UK',
        box2tabArr4:'Canada',
        box2tabcententArr1:[
            {
                subTitle:'EIC Bắc Kinh',
                position:"Địa chỉ:Phòng 1801-1802, Tòa nhà C, Trung tâm Yintai, Tòa nhà 3, Số 2, Đường Kiến Quốc Môn ngoại, Quận Triều Dương, Bắc Kinh",
                phone:'Điện thoại: +86 400-1010-123',
            },
            {
                subTitle:'EIC Bắc Kinh',
                position:"Địa chỉ:Phòng 801-802, Tầng 8, Tháp B (Cánh Bắc), Tòa nhà Tân Trung Quan, Số 19 Phố Trung Quan Thôn, Quận Hải Điến, Bắc Kinh",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Bắc Kinh',
                position:"Địa chỉ:Tầng 5, Tháp A, Tòa nhà văn phòng Kiến Ngoại SOHO, Số 39 Đường vành đai 3 phía Đông, Quận Triều Dương, Bắc Kinh",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Bắc Kinh',
                position:"Địa chỉ:Tầng 7, Tháp A, Tòa nhà Kỹ thuật số, Số 2 Phố Nam Trung Quan Thôn, Quận Hải Điến, Bắc Kinh",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Quảng Châu',
                position:"Địa chỉ:Phòng 1701, Tầng 17, Tháp 1, Taikoo Hui, Số 385 Đường Thiên Hà, quận Thiên Hà, Thành phố Quảng Châu, Tỉnh Quảng Đông",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Quảng Châu',
                position:"Địa chỉ:Tầng 2, 8, 16, Trung tâm thương mại ga Đại Mã, số 33, đường số 5 Trung Sơn, quận Việt Tú, Thành phố Quảng Châu, Tỉnh Quảng Đông",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Quảng Châu',
                position:"Địa chỉ:Cửa hàng 101, Cổng Đông, Tòa nhà Trung tâm Thông tin, Số 1, Đường Trung Lưu, Thị trấn Đại học Quảng Châu, Quận Phiên Ngung, Thành phố Quảng Châu, Tỉnh Quảng Đông",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Quảng Châu',
                position:"Địa chỉ:Phòng 1801-1802, Tòa nhà C, Trung tâm Yintai, Tòa nhà 3, Số 2, Đường Kiến Quốc Môn ngoại, Quận Triều Dương, Bắc Kinh",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thâm Quyến',
                position:"Địa chỉ:Tầng 16, Tháp A và Phòng 205, Tháp B, Tòa nhà Phòng Thương mại Quốc tế, Số 138 Đường 1 Phúc Hóa, Quận Phúc Điền, Thâm Quyến",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thâm Quyến',
                position:"Địa chỉ:Số 904-907, Tầng 9, Tháp C, Giai đoạn 2, Quảng trường trung tâm thương mại Thiên Lý, Coastal City, Quận Nam Sơn, Thâm Quyến ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thâm Quyến',
                position:"Địa chỉ:Phòng 402, Tháp B, Renheng Mengchuang Plaza, phố Long Thành, Quận Long Cương, Thâm Quyến",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thượng Hải',
                position:"Địa chỉ:Phòng 2901, Ciro’s Plaza, Số 388 Đường Tây Nam Kinh, Quận Hoàng Phố, Thượng Hải",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thượng Hải',
                position:"Địa chỉ:Phòng 804-805, Tòa nhà B, Trung tâm Thương mại Quốc tế CICC, Số 333 Đường Tào Tây Bắc, Quận Từ Hối, Thượng Hải ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thượng Hải',
                position:"Địa chỉ:Tầng 18, Trung tâm tài chính Trường Hưng, số 288 đường Tây Nam Kinh, quận Hoàng Phố, Thượng Hải",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Vũ Hán',
                position:"Địa chỉ:ầng 20, Tòa nhà văn phòng quốc tế Dung Trung, số 889 đường Lạc Vũ, Khu phát triển công nghệ mới Đông Hồ, thành phố Vũ Hán",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Vũ Hán',
                position:"Địa chỉ:Tầng 21, Tòa nhà văn phòng Vũ Hán Plaza, Số 358 Đại lộ Giải Phóng, Quận Giang Hán, Thành phố Vũ Hán",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Vũ Hán',
                position:"Địa chỉ:Tầng 36 và 41, Tòa nhà văn phòng Trung Thượng Plaza, Số 7 Đường Trung Nam, Quận Vũ Xương, Thành phố Vũ Hán",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Tế Nan',
                position:"Địa chỉ:Tầng 2, Khu B, Tòa văn phòng khách sạn Xuefu, số 6 đường Thiên Phật Sơn, thành phố Tế Nam ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Tế Nan',
                position:"Địa chỉ:Tầng 5, Tòa nhà phía Nam, Tòa nhà Thiên Phật Sơn, Số 15-1, Đường Tây Văn Hoa, Quận Lệ Hạ, Thành phố Tế Nam",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Tế Nan',
                position:"Địa chỉ:Phòng 301, Tòa nhà 5, Khu A7, Hanyu Jingu, Khu công nghệ cao Tế Nam, Thành phố Tế Nam (Cao tốc Tòa nhà khoa học công nghệ Tân Liên)",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Tế Nan',
                position:"Địa chỉ:Phòng 510, Tòa nhà chính, Khu A, Shuyu Plaza, Đường Tử Vi, Khu Đại học Trường Thanh, Tế Nam ",
                phone:'Điện thoại: +86 400-1000-918',
            },
            {
                subTitle:'EIC Thanh Đảo',
                position:"Địa chỉ:Tầng 20 & 21, Tháp B, Sino-Ocean Plaza, Số 61 Đường Trung Hồng Kông, Thành phố Thanh Đảo ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Yên Đài',
                position:"Địa chỉ:Phòng 1516, Tầng 15, Tòa nhà 100A, Sunshine, Đường Hải Cảng, Quận Chí Phúc, Thành phố Yên Đài ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thành Đô',
                position:"Địa chỉ:Tầng 19, Tòa nhà Văn phòng 1, Trung tâm Tài chính Quốc tế IFS, Số 1, Đoạn 3, Đường Hồng Tinh, Quận Tấn Giang, Thành Đô ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thành Đô',
                position:"Địa chỉ:Phòng 513-514, Đơn nguyên 2, Tòa nhà 10, Poly Tân Thiên Địa, Đường Tây Phổ Bách Thảo, Quận Bì Đô, Thành phố Thành Đô",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Trùng Khánh',
                position:"Địa chỉ:Phòng 2307/2308, Tòa nhà C, Trung tâm Tạ Xin, Số 68, Đường số 1 Dương Hà, Quận Giang Bắc, Trùng Khánh",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Trùng Khánh',
                position:"Địa chỉ:Tầng 30, Trung tâm tài chính thế giới WFC, số 188 đường Dân Thấu, quận Du Trung, Trùng Khánh",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Hàng Châu',
                position:"Địa chỉ:Phòng 603-604-605-606, Tòa nhà F, Trung tâm Quốc tế Hoàng Long, Số 77 Đường Học Viện, Phố Từ Nguyên, Quận Tây Hồ, Hàng Châu",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Ninh Ba',
                position:"Địa chỉ:Tầng 7, Tháp B, Trung tâm Toàn cầu Ngân Ức, Số 42 Phố Dược Hàng, Quận Hải Thụ, Thành phố Ninh Ba, Tỉnh Chiết Giang",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Nam Kinh',
                position:"Địa chỉ:Tháp A8, Tầng 29, Shangmao Century Plaza, Số 49 Đường Trung Sơn Nam, Quận Tần Hoài, Thành phố Nam Kinh",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Tô Châu',
                position:"Địa chỉ:Tầng 26, Tháp Tây, Zhonghai Wealth Center, Khu thương mại trung tâm Hồ Tây, Khu công nghiệp Tô Châu, tỉnh Giang Tô",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Vô Tích',
                position:"Địa chỉ:Phòng 3310-3311, Tháp 1, Tòa nhà văn phòng Center66, Số 139 Đường Trung Nhân Dân, Quận Lương Tây, Thành phố Vô Tích",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Hợp Phì',
                position:"Địa chỉ:Tầng 28 & 29, Tòa nhà văn phòng số 6, Wanda Plaza, số 130 đường Mã An Sơn, quận Bảo Hà, thành phố Hợp Phì, tỉnh An Huy",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thạch Gia Trang',
                position:"Địa chỉ:Phòng 2214, Tầng 22, Tháp A, Tòa nhà Văn phòng Tài nguyên Trung Quốc, Quận Kiều Tây, Thạch Gia Trang",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Hàm Đan',
                position:"Địa chỉ:Phòng 2702, Tầng 27, Tòa nhà T6, Trung tâm Toàn cầu 456, Đường Đông Nhân Dân, Quận Thung Di, Thành phố Hàm Đan, Tỉnh Hà Bắc",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Trịnh Châu',
                position:"Địa chỉ:Phòng 1907-1909, Tầng 19, Tòa nhà 2, Số 68 Đường Kinh Tam, Quận Kim Thủy, Thành phố Trịnh Châu, Tỉnh Hà Nam",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Tây An',
                position:"Địa chỉ:Phòng 4C, Tầng 4, Tòa nhà Telecom Plaza Podium, Số 56 Đường Khu Công nghệ cao, Tây An ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thái Nguyên',
                position:"Địa chỉ:Tầng 10, Trung tâm Junwei Fortune, Số 123, Phố Trường Phong, Quận Tiểu Đám, Thành phố Thái Nguyên, Tỉnh Sơn Tây ",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Thiên Tân',
                position:"Địa chỉ:Phòng 1801-1802, Tòa nhà Thương mại Century Metropolis, Số 183 Đường Nam Kinh, Quận Hòa Bình, Thiên Tân",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Phúc Châu',
                position:"Địa chỉ:Phòng 702 & 2301, Tháp Đông, Trung tâm Quốc tế Tam Thành, Số 118 Đường Ngũ Tứ, Quận Cổ Lâu, Thành phố Phúc Châu",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Hạ Môn',
                position:"Địa chỉ:Phòng 1601-1603, Trung tâm Fortune, Số 100, Đường Lữ Giang, Quận Tai Minh, Thành phố Hạ Môn, Tỉnh Phúc Kiến",
                phone:'Điện thoại: +86 400-1010-123',
            },
            {
                subTitle:'EIC Nam Ninh',
                position:"Địa chỉ:Phòng 1803, Tòa nhà B, Tòa nhà Tài nguyên Trung Quốc, Số 136-2 Đại lộ Dân Thấu, Quận Thanh Tú, Thành phố Nam Ninh, Quảng Tây",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Chu Hải',
                position:"Địa chỉ:Tầng 6, Trung tâm quốc tế Zhonghai, quận Tương Châu, thành phố Chu Hải (Tòa nhà văn phòng Hoàn Vũ Thành, số 68 đường Tiễn Hà Bắc)",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Chu Hải',
                position:"Địa chỉ:Tầng 2 & 6, Tòa nhà D, Cảng thông tin Chu Hải, Số 88 Đường Tương Sơn, Đường Gia Vạn, Khu công nghệ cao, Thành phố Chu Hải",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Chu Hải',
                position:"Địa chỉ:Cửa hàng số 130, số 66, Khu phức hợp thương mại văn phòng, Poly International Plaza, Hoành Cầm, Thành phố Chu Hải",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Trường Sa',
                position:"Địa chỉ:Tầng 24 & 27, Tòa nhà thương mại Bình Hòa Đàng, số 88 đường Hoàng Hưng Trung, Thành phố Trường Sa",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Trường Sa',
                position:"Địa chỉ:Tầng 8, Tòa nhà thương mại, Khách sạn Thần Nông, Số 269, Đoạn 3, Đường Trung Phù Dung, Thành phố Trường Sa",
                phone:'Điện thoại: +86 400-1000-918',
            },{
                subTitle:'EIC Thẩm Dương',
                position:"Địa chỉ:Phòng 2905, Tháp A, Tòa nhà Tài nguyên Trung Quốc, Số 286 Phố Thanh Niên, Quận Hòa Bình, Thành phố Thẩm Dương",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Đại Liên',
                position:"Địa chỉ:Tầng 28, Tòa nhà Hồng Nguyên, Số 23 Đường Nhân Dân, Quận Trung Sơn, Thành phố Đại Liên",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Trường Xuân',
                position:"Địa chỉ:Tầng 13, Tháp A, Tòa nhà Bank of China, Số 727 Đường Tây An, Quận Triều Dương, Trường Xuân",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Cáp Nhĩ Tân',
                position:"Địa chỉ:Phòng 3403, Tòa nhà T2, Tòa nhà văn phòng Trung tâm R&F, Số 660 Đường Tây Hữu Nghị, Quận Đạo Lý, Thành phố Cáp Nhĩ Tân, Tỉnh Hắc Long Giang",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Nam Xương',
                position:"Địa chỉ:Tầng 32, Trung tâm thương mại Walden, số 1669 Đại lộ Hồng Cô Trung, quận Hồng Cô Than, thành phố Nam Xương, tỉnh Giang Tây",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Côn Minh',
                position:"Địa chỉ:Tầng 16, Tòa nhà phía Đông, trung tâm thương mại Số 1 Đông Phương Bạch Phong, Số 1 Đường Sùng Nhân, Quận Ngũ Hoa, Thành phố Côn Minh",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Hải Khẩu',
                position:"Địa chỉ:Phòng 06-07, Tầng 24, Tháp B, Tòa nhà Tài nguyên Trung Quốc, Số 7 Đường Minh Chu, Quận Long Hoa, Thành phố Hải Khẩu",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Phật Sơn',
                position:"Địa chỉ:Phòng 1301, Tầng 13, Tòa nhà Hoa Huy, Số 46 Đường Tổ Miếu, Quận Thiền Thành, Thành phố Phật Sơn, Tỉnh Quảng Đông",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Đông Quan',
                position:"Địa chỉ:Tầng 11, Tòa nhà Hoa Khải, Quận Nam Thành, Thành phố Đông Quan",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Trung Sơn',
                position:"Địa chỉ:Phòng 301, Tầng 3, Tháp Nam, Trung tâm Quốc tế Đại Đăng Vũ (Khách sạn Đại Đăng Vũ), Số 101 Đường Đông Nguyên, Quận Đông, Thành phố Trung Sơn, Tỉnh Quảng Đông",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Sán Đầu',
                position:"Địa chỉ:Phòng 1301, Tòa nhà Triều Sơn Galaxy, Số 1 Đường Kim Hoàn Nam, Quận Long Hồ, Thành phố Sán Đầu",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Hohhot',
                position:"Địa chỉ:Phòng 811, Tòa nhà B, Wanda Plaza, Phố Đông Tân Hoa Xã, Quận Trại Hãn, Thành phố Hohhot",
                phone:'Điện thoại: +86 400-1010-123',
            },{
                subTitle:'EIC Lan Châu',
                position:"Địa chỉ:Phòng 1302, Tầng 13, Tháp A, Hồng Vân Mậu, Số 2 Đường Thanh Dương, Phố Quảng Vũ Môn, Quận Thành Quan, Thành phố Lan Châu, Tỉnh Cam Túc",
                phone:'Điện thoại: +86 400-1010-123',
            },
        ],
        box2tabcententArr2:[
            {
                subTitle:'EIC Sydney',
                position:"Địa chỉ:Suite 506-508, 5th Floor, Manning Building, 451 Pitt Street, Sydney, NSW",
                phone:'Điện thoại: +61 2 9281 5000',
            },{
                subTitle:'EIC Melbourne',
                position:"Địa chỉ:Suite 1, 13th Floor, 55 Swanston Street, Melbourne, VIC",
                phone:'Điện thoại: +61 3 9654 8890',
            },{
                subTitle:'EIC Adelaide',
                position:"Địa chỉ:9th Floor, 90 King William Street, Adelaide, SA",
                phone:'Điện thoại: +61 8 8212 4651',
            },{
                subTitle:'EIC Brisbane',
                position:"Địa chỉ:Suite 2, 3rd Floor, 243 Edward Street, Brisbane City, QLD",
                phone:'Điện thoại: +61 7 3221 7471',
            },
        ],
        box2tabcententArr3:[
            {
                subTitle:'EIC Manchester',
                position:"Địa chỉ:Room 301, Peter House, 16 Oxford Street, Manchester",
                phone:'Điện thoại: +44 161 209 3330',
            },{
                subTitle:'EIC London',
                position:"Địa chỉ:60 St. Martins Lane, Covent Garden, London",
                phone:'Điện thoại: +44 161 209 3330',
            },{
                subTitle:'EIC Birmingham ',
                position:"Địa chỉ:The Southsidebuilding 31 Hurst ST Birmingham B5 4BD",
                phone:'Điện thoại: +44 161 209 3330',
            },
        ], 
        box2tabcententArr4:[
            {
                subTitle:'EIC Vancouver',
                position:"Địa chỉ:No. 490 1111 Melville Street, Vancouver, BC",
                phone:'Điện thoại: +1 604-661-2118',
            },{
                subTitle:'EIC Toronto',
                position:"Địa chỉ:111, 5460 Yong Street, Toronto, ON",
                phone:'Điện thoại: +1 416-225-7888',
            },
        ],
    //     //box2tabArr1切换第一个的
    //     box2tabcentent1_1subTitle:'EIC Bắc Kinh',
    //     box2tabcentent1_1position:"Địa chỉ:Phòng 1801-1802, Tòa nhà C, Trung tâm Yintai, Tòa nhà 3, Số 2, Đường Kiến Quốc Môn ngoại, Quận Triều Dương, Bắc Kinh",
    //     box2tabcentent1_1phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_2subTitle:'EIC Bắc Kinh',
    //     box2tabcentent1_2position:"Địa chỉ:Phòng 801-802, Tầng 8, Tháp B (Cánh Bắc), Tòa nhà Tân Trung Quan, Số 19 Phố Trung Quan Thôn, Quận Hải Điến, Bắc Kinh",
    //     box2tabcentent1_2phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_3subTitle:'EIC Bắc Kinh',
    //     box2tabcentent1_3position:"Địa chỉ:Tầng 5, Tháp A, Tòa nhà văn phòng Kiến Ngoại SOHO, Số 39 Đường vành đai 3 phía Đông, Quận Triều Dương, Bắc Kinh ",
    //     box2tabcentent1_3phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_4subTitle:'EIC Bắc Kinh',
    //     box2tabcentent1_4position:'Tầng 7, Tháp A, Tòa nhà Kỹ thuật số, Số 2 Phố Nam Trung Quan Thôn, Quận Hải Điến, Bắc Kinh',
    //     box2tabcentent1_4phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_5subTitle:'EIC Quảng Châu',
    //     box2tabcentent1_5position:'Phòng 1701, Tầng 17, Tháp 1, Taikoo Hui, Số 385 Đường Thiên Hà, quận Thiên Hà, Thành phố Quảng Châu, Tỉnh Quảng Đông',
    //     box2tabcentent1_5phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_6subTitle:'EIC Quảng Châu',
    //     box2tabcentent1_6position:'Tầng 2, 8, 16, Trung tâm thương mại ga Đại Mã, số 33, đường số 5 Trung Sơn, quận Việt Tú, Thành phố Quảng Châu, Tỉnh Quảng Đông',
    //     box2tabcentent1_6phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_7subTitle:'EIC Quảng Châu',
    //     box2tabcentent1_7position:'Số 507-508, Tầng 5 Tòa nhà công nghệ cao, Số 900 Đường Bắc Thiên Hà, quận Thiên Hà, Thành phố Quảng Châu, Tỉnh Quảng Đông',
    //     box2tabcentent1_7phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_8subTitle:'EIC Quảng Châu',
    //     box2tabcentent1_8position:'Cửa hàng 101, Cổng Đông, Tòa nhà Trung tâm Thông tin, Số 1, Đường Trung Lưu, Thị trấn Đại học Quảng Châu, Quận Phiên Ngung, Thành phố Quảng Châu, Tỉnh Quảng Đông',
    //     box2tabcentent1_8phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_9subTitle:'EIC Thâm Quyến',
    //     box2tabcentent1_9position:'Tầng 16, Tháp A và Phòng 205, Tháp B, Tòa nhà Phòng Thương mại Quốc tế, Số 138 Đường 1 Phúc Hóa, Quận Phúc Điền, Thâm Quyến',
    //     box2tabcentent1_9phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_10subTitle:'EIC Thâm Quyến',
    //     box2tabcentent1_10position:'Số 904-907, Tầng 9, Tháp C, Giai đoạn 2, Quảng trường trung tâm thương mại Thiên Lý, Coastal City, Quận Nam Sơn, Thâm Quyến ',
    //     box2tabcentent1_10phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_11subTitle:'EIC Thâm Quyến',
    //     box2tabcentent1_11position:'Phòng 402, Tháp B, Renheng Mengchuang Plaza, phố Long Thành, Quận Long Cương, Thâm Quyến',
    //     box2tabcentent1_11phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_12subTitle:'EIC Thượng Hải',
    //     box2tabcentent1_12position:'Phòng 2901, Ciro’s Plaza, Số 388 Đường Tây Nam Kinh, Quận Hoàng Phố, Thượng Hải',
    //     box2tabcentent1_12phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_13subTitle:'EIC Thượng Hải',
    //     box2tabcentent1_13position:'Phòng 804-805, Tòa nhà B, Trung tâm Thương mại Quốc tế CICC, Số 333 Đường Tào Tây Bắc, Quận Từ Hối, Thượng Hải ',
    //     box2tabcentent1_13phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_14subTitle:'EIC Thượng Hải',
    //     box2tabcentent1_14position:'Tầng 18, Trung tâm tài chính Trường Hưng, số 288 đường Tây Nam Kinh, quận Hoàng Phố, Thượng Hải',
    //     box2tabcentent1_14phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_15subTitle:'EIC Vũ Hán ',
    //     box2tabcentent1_15position:'ầng 20, Tòa nhà văn phòng quốc tế Dung Trung, số 889 đường Lạc Vũ, Khu phát triển công nghệ mới Đông Hồ, thành phố Vũ Hán',
    //     box2tabcentent1_15phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_16subTitle:'EIC Vũ Hán ',
    //     box2tabcentent1_16position:'Tầng 21, Tòa nhà văn phòng Vũ Hán Plaza, Số 358 Đại lộ Giải Phóng, Quận Giang Hán, Thành phố Vũ Hán',
    //     box2tabcentent1_16phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_17subTitle:'EIC Vũ Hán ',
    //     box2tabcentent1_17position:'Tầng 36 và 41, Tòa nhà văn phòng Trung Thượng Plaza, Số 7 Đường Trung Nam, Quận Vũ Xương, Thành phố Vũ Hán ',
    //     box2tabcentent1_17phone:'Điện thoại: ',
    //     box2tabcentent1_18subTitle:'EIC Tế Nan',
    //     box2tabcentent1_18position:'Tầng 2, Khu B, Tòa văn phòng khách sạn Xuefu, số 6 đường Thiên Phật Sơn, thành phố Tế Nam ',
    //     box2tabcentent1_18phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_19subTitle:'EIC Tế Nan',
    //     box2tabcentent1_19position:'Tầng 5, Tòa nhà phía Nam, Tòa nhà Thiên Phật Sơn, Số 15-1, Đường Tây Văn Hoa, Quận Lệ Hạ, Thành phố Tế Nam',
    //     box2tabcentent1_19phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_20subTitle:'EIC Tế Nan',
    //     box2tabcentent1_20position:'Phòng 301, Tòa nhà 5, Khu A7, Hanyu Jingu, Khu công nghệ cao Tế Nam, Thành phố Tế Nam (Cao tốc Tòa nhà khoa học công nghệ Tân Liên)',
    //     box2tabcentent1_20phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_21subTitle:'EIC Tế Nan',
    //     box2tabcentent1_21position:'Phòng 510, Tòa nhà chính, Khu A, Shuyu Plaza, Đường Tử Vi, Khu Đại học Trường Thanh, Tế Nam ',
    //     box2tabcentent1_21phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_22subTitle:'EIC Thanh Đảo',
    //     box2tabcentent1_22position:'Tầng 20 & 21, Tháp B, Sino-Ocean Plaza, Số 61 Đường Trung Hồng Kông, Thành phố Thanh Đảo ',
    //     box2tabcentent1_22phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_23subTitle:'EIC Yên Đài',
    //     box2tabcentent1_23position:'Phòng 1516, Tầng 15, Tòa nhà 100A, Sunshine, Đường Hải Cảng, Quận Chí Phúc, Thành phố Yên Đài ',
    //     box2tabcentent1_23phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_24subTitle:'EIC Thành Đô',
    //     box2tabcentent1_24position:'Tầng 19, Tòa nhà Văn phòng 1, Trung tâm Tài chính Quốc tế IFS, Số 1, Đoạn 3, Đường Hồng Tinh, Quận Tấn Giang, Thành Đô ',
    //     box2tabcentent1_24phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_25subTitle:'EIC Thành Đô',
    //     box2tabcentent1_25position:'Phòng 513-514, Đơn nguyên 2, Tòa nhà 10, Poly Tân Thiên Địa, Đường Tây Phổ Bách Thảo, Quận Bì Đô, Thành phố Thành Đô',
    //     box2tabcentent1_25phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_26subTitle:'EIC Trùng Khánh',
    //     box2tabcentent1_26position:'Phòng 2307/2308, Tòa nhà C, Trung tâm Tạ Xin, Số 68, Đường số 1 Dương Hà, Quận Giang Bắc, Trùng Khánh',
    //     box2tabcentent1_26phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_27subTitle:'EIC Trùng Khánh',
    //     box2tabcentent1_27position:'Tầng 30, Trung tâm tài chính thế giới WFC, số 188 đường Dân Thấu, quận Du Trung, Trùng Khánh',
    //     box2tabcentent1_27phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_28subTitle:'EIC Hàng Châu',
    //     box2tabcentent1_28position:'Phòng 603-604-605-606, Tòa nhà F, Trung tâm Quốc tế Hoàng Long, Số 77 Đường Học Viện, Phố Từ Nguyên, Quận Tây Hồ, Hàng Châu',
    //     box2tabcentent1_28phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_29subTitle:'EIC Ninh Ba',
    //     box2tabcentent1_29position:'Tầng 7, Tháp B, Trung tâm Toàn cầu Ngân Ức, Số 42 Phố Dược Hàng, Quận Hải Thụ, Thành phố Ninh Ba, Tỉnh Chiết Giang',
    //     box2tabcentent1_29phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_30subTitle:'EIC Nam Kinh',
    //     box2tabcentent1_30position:'Tháp A8, Tầng 29, Shangmao Century Plaza, Số 49 Đường Trung Sơn Nam, Quận Tần Hoài, Thành phố Nam Kinh ',
    //     box2tabcentent1_30phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_31subTitle:'EIC Tô Châu',
    //     box2tabcentent1_31position:'Tầng 26, Tháp Tây, Zhonghai Wealth Center, Khu thương mại trung tâm Hồ Tây, Khu công nghiệp Tô Châu, tỉnh Giang Tô',
    //     box2tabcentent1_31phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_32subTitle:'EIC Vô Tích',
    //     box2tabcentent1_32position:'Phòng 3310-3311, Tháp 1, Tòa nhà văn phòng Center66, Số 139 Đường Trung Nhân Dân, Quận Lương Tây, Thành phố Vô Tích',
    //     box2tabcentent1_32phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_33subTitle:'EIC Hợp Phì',
    //     box2tabcentent1_33position:'Tầng 28 & 29, Tòa nhà văn phòng số 6, Wanda Plaza, số 130 đường Mã An Sơn, quận Bảo Hà, thành phố Hợp Phì, tỉnh An Huy ',
    //     box2tabcentent1_33phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_34subTitle:'EIC Thạch Gia Trang',
    //     box2tabcentent1_34position:'Phòng 2214, Tầng 22, Tháp A, Tòa nhà Văn phòng Tài nguyên Trung Quốc, Quận Kiều Tây, Thạch Gia Trang ',
    //     box2tabcentent1_34phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_35subTitle:'EIC Hàm Đan',
    //     box2tabcentent1_35position:'Phòng 2702, Tầng 27, Tòa nhà T6, Trung tâm Toàn cầu 456, Đường Đông Nhân Dân, Quận Thung Di, Thành phố Hàm Đan, Tỉnh Hà Bắc',
    //     box2tabcentent1_35phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_36subTitle:'EIC Trịnh Châu',
    //     box2tabcentent1_36position:'Phòng 1907-1909, Tầng 19, Tòa nhà 2, Số 68 Đường Kinh Tam, Quận Kim Thủy, Thành phố Trịnh Châu, Tỉnh Hà Nam',
    //     box2tabcentent1_36phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_37subTitle:'EIC Tây An',
    //     box2tabcentent1_37position:'Phòng 4C, Tầng 4, Tòa nhà Telecom Plaza Podium, Số 56 Đường Khu Công nghệ cao, Tây An ',
    //     box2tabcentent1_37phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_38subTitle:'EIC Thái Nguyên',
    //     box2tabcentent1_38position:'Tầng 10, Trung tâm Junwei Fortune, Số 123, Phố Trường Phong, Quận Tiểu Đám, Thành phố Thái Nguyên, Tỉnh Sơn Tây ',
    //     box2tabcentent1_38phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_39subTitle:'EIC Thiên Tân ',
    //     box2tabcentent1_39position:'Phòng 1801-1802, Tòa nhà Thương mại Century Metropolis, Số 183 Đường Nam Kinh, Quận Hòa Bình, Thiên Tân',
    //     box2tabcentent1_39phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_40subTitle:'EIC Phúc Châu',
    //     box2tabcentent1_40position:'Phòng 702 & 2301, Tháp Đông, Trung tâm Quốc tế Tam Thành, Số 118 Đường Ngũ Tứ, Quận Cổ Lâu, Thành phố Phúc Châu',
    //     box2tabcentent1_40phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_41subTitle:'EIC Hạ Môn ',
    //     box2tabcentent1_41position:'Phòng 1601-1603, Trung tâm Fortune, Số 100, Đường Lữ Giang, Quận Tai Minh, Thành phố Hạ Môn, Tỉnh Phúc Kiến',
    //     box2tabcentent1_41phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_42subTitle:'EIC Nam Ninh ',
    //     box2tabcentent1_42position:'Phòng 1803, Tòa nhà B, Tòa nhà Tài nguyên Trung Quốc, Số 136-2 Đại lộ Dân Thấu, Quận Thanh Tú, Thành phố Nam Ninh, Quảng Tây',
    //     box2tabcentent1_42phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_43subTitle:'EIC Chu Hải',
    //     box2tabcentent1_43position:'Tầng 6, Trung tâm quốc tế Zhonghai, quận Tương Châu, thành phố Chu Hải (Tòa nhà văn phòng Hoàn Vũ Thành, số 68 đường Tiễn Hà Bắc)',
    //     box2tabcentent1_43phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_60phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_60subTitle:'EIC Chu Hải',
    //     box2tabcentent1_60position:'Tầng 2 & 6, Tòa nhà D, Cảng thông tin Chu Hải, Số 88 Đường Tương Sơn, Đường Gia Vạn, Khu công nghệ cao, Thành phố Chu Hải',
    //     box2tabcentent1_44subTitle:'EIC Chu Hải',
    //     box2tabcentent1_44position:'Cửa hàng số 130, số 66, Khu phức hợp thương mại văn phòng, Poly International Plaza, Hoành Cầm, Thành phố Chu Hải',
    //     box2tabcentent1_44phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_45subTitle:'EIC Trường Sa',
    //     box2tabcentent1_45position:'Tầng 24 & 27, Tòa nhà thương mại Bình Hòa Đàng, số 88 đường Hoàng Hưng Trung, Thành phố Trường Sa',
    //     box2tabcentent1_45phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_46subTitle:'EIC Trường Sa',
    //     box2tabcentent1_46position:'Tầng 8, Tòa nhà thương mại, Khách sạn Thần Nông, Số 269, Đoạn 3, Đường Trung Phù Dung, Thành phố Trường Sa',
    //     box2tabcentent1_46phone:'Điện thoại: +86 400-1000-918',
    //     box2tabcentent1_47subTitle:'EIC Thẩm Dương',
    //     box2tabcentent1_47position:'Phòng 2905, Tháp A, Tòa nhà Tài nguyên Trung Quốc, Số 286 Phố Thanh Niên, Quận Hòa Bình, Thành phố Thẩm Dương ',
    //     box2tabcentent1_47phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_48subTitle:'EIC Đại Liên',
    //     box2tabcentent1_48position:'Tầng 28, Tòa nhà Hồng Nguyên, Số 23 Đường Nhân Dân, Quận Trung Sơn, Thành phố Đại Liên ',
    //     box2tabcentent1_48phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_49subTitle:'EIC Trường Xuân',
    //     box2tabcentent1_49position:'Tầng 13, Tháp A, Tòa nhà Bank of China, Số 727 Đường Tây An, Quận Triều Dương, Trường Xuân ',
    //     box2tabcentent1_49phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_50subTitle:'EIC Cáp Nhĩ Tân',
    //     box2tabcentent1_50position:'Phòng 3403, Tòa nhà T2, Tòa nhà văn phòng Trung tâm R&F, Số 660 Đường Tây Hữu Nghị, Quận Đạo Lý, Thành phố Cáp Nhĩ Tân, Tỉnh Hắc Long Giang ',
    //     box2tabcentent1_50phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_51subTitle:'EIC Nam Xương',
    //     box2tabcentent1_51position:'Tầng 32, Trung tâm thương mại Walden, số 1669 Đại lộ Hồng Cô Trung, quận Hồng Cô Than, thành phố Nam Xương, tỉnh Giang Tây',
    //     box2tabcentent1_51phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_52subTitle:'EIC Côn Minh',
    //     box2tabcentent1_52position:'Tầng 16, Tòa nhà phía Đông, trung tâm thương mại Số 1 Đông Phương Bạch Phong, Số 1 Đường Sùng Nhân, Quận Ngũ Hoa, Thành phố Côn Minh',
    //     box2tabcentent1_52phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_53subTitle:'EIC Hải Khẩu ',
    //     box2tabcentent1_53position:'Phòng 06-07, Tầng 24, Tháp B, Tòa nhà Tài nguyên Trung Quốc, Số 7 Đường Minh Chu, Quận Long Hoa, Thành phố Hải Khẩu',
    //     box2tabcentent1_53phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_54subTitle:'EIC Phật Sơn',
    //     box2tabcentent1_54position:'Phòng 1301, Tầng 13, Tòa nhà Hoa Huy, Số 46 Đường Tổ Miếu, Quận Thiền Thành, Thành phố Phật Sơn, Tỉnh Quảng Đông',
    //     box2tabcentent1_54phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_55subTitle:'EIC Đông Quan',
    //     box2tabcentent1_55position:'Tầng 11, Tòa nhà Hoa Khải, Quận Nam Thành, Thành phố Đông Quan',
    //     box2tabcentent1_55phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_56subTitle:'EIC Trung Sơn',
    //     box2tabcentent1_56position:'Phòng 301, Tầng 3, Tháp Nam, Trung tâm Quốc tế Đại Đăng Vũ (Khách sạn Đại Đăng Vũ), Số 101 Đường Đông Nguyên, Quận Đông, Thành phố Trung Sơn, Tỉnh Quảng Đông',
    //     box2tabcentent1_56phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_57subTitle:'EIC Sán Đầu',
    //     box2tabcentent1_57position:'Phòng 1301, Tòa nhà Triều Sơn Galaxy, Số 1 Đường Kim Hoàn Nam, Quận Long Hồ, Thành phố Sán Đầu',
    //     box2tabcentent1_57phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_58subTitle:'EIC Hohhot',
    //     box2tabcentent1_58position:'Phòng 811, Tòa nhà B, Wanda Plaza, Phố Đông Tân Hoa Xã, Quận Trại Hãn, Thành phố Hohhot',
    //     box2tabcentent1_58phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent1_59subTitle:'EIC Lan Châu',
    //     box2tabcentent1_59position:'Phòng 1302, Tầng 13, Tháp A, Hồng Vân Mậu, Số 2 Đường Thanh Dương, Phố Quảng Vũ Môn, Quận Thành Quan, Thành phố Lan Châu, Tỉnh Cam Túc',
    //     box2tabcentent1_59phone:'Điện thoại: +86 400-1010-123',
    //     // box2tabcentent1_60subTitle:'',
    //     // box2tabcentent1_60position:'',
    //     // box2tabcentent1_60phone:'Điện thoại: ',

    //     //box2tabArr2切换第二个的
    //     box2tabcentent2_1subTitle:'EIC Sydney',
    //     box2tabcentent2_1position:"Địa chỉ:Suite 506-508, 5th Floor, Manning Building, 451 Pitt Street, Sydney, NSW",
    //     box2tabcentent2_1phone:'Điện thoại: +61 2 9281 5000',
    //     box2tabcentent2_2subTitle:'EIC Melbourne',
    //     box2tabcentent2_2position:"Địa chỉ:Suite 1, 13th Floor, 55 Swanston Street, Melbourne, VIC",
    //     box2tabcentent2_2phone:'Điện thoại: +61 3 9654 8890',
    //     box2tabcentent2_3subTitle:'EIC Adelaide',
    //     box2tabcentent2_3position:"Địa chỉ:9th Floor, 90 King William Street, Adelaide, SA",
    //     box2tabcentent2_3phone:'Điện thoại: +61 8 8212 4651',
    //     box2tabcentent2_4subTitle:'EIC Brisbane',
    //     box2tabcentent2_4position:"Địa chỉ:Suite 2, 3rd Floor, 243 Edward Street, Brisbane City, QLD",
    //     box2tabcentent2_4phone:'Điện thoại: +61 7 3221 7471',
    //     //box2tabArr3切换第三个的
    //     box2tabcentent3_1subTitle:'EIC Manchester',
    //     box2tabcentent3_1position:"Địa chỉ:Room 301, Peter House, 16 Oxford Street, Manchester",
    //     box2tabcentent3_1phone:'Điện thoại: +44 161 209 3330',
    //     box2tabcentent3_2subTitle:'EIC London',
    //     box2tabcentent3_2position:"Địa chỉ:60 St. Martins Lane, Covent Garden, London",
    //     box2tabcentent3_2phone:'Điện thoại: +86 400-1010-123',
    //     box2tabcentent3_3subTitle:'EIC Bắc Kinh',
    //     box2tabcentent3_3position:"Địa chỉ:Địa chỉ: Phòng 1801-1802, Tòa nhà C, Trung tâm Yintai, Tòa nhà 3, Số 2, Đường Kiến Quốc Môn ngoại, Quận Triều Dương, Bắc Kinh Điện",
    //     box2tabcentent3_3phone:'Điện thoại: +86 400-1010-123',
    //     //box2tabArr4切换第4个的
    //     box2tabcentent4_1subTitle:'EIC Vancouver',
    //     box2tabcentent4_1position:"Địa chỉ:No. 490 1111 Melville Street, Vancouver, BC",
    //     box2tabcentent4_1phone:'Điện thoại: +1 604-661-2118',
    //     box2tabcentent4_2subTitle:'EIC Toronto',
    //     box2tabcentent4_2position:"Địa chỉ:111, 5460 Yong Street, Toronto, ON",
    //     box2tabcentent4_2phone:'Điện thoại: +1 416-225-7888',
    },
    specialty:{
        title:'NƠI BẮT ĐẦU CHO SỰ NGHIỆP CỦA BẠN',
        subTitle:'TÌM HIỂU VỀ CÁC CHUYÊN NGÀNH ĐÀO TẠO CHẤT LƯỢNG TỐT NHẤT',
        searchplaceholder:'Tìm ngành học',
        professionalLevel:"Bậc học",
        professionalType:"Chuyên khoa",
        all:"Chuyên khoa",
    },
    specialtyDetails:{
        levelName:"Bậc học",
        typeName:"Lĩnh vực",
        pName:"Loại chuyên nghiệp",
        code:"Mã ngành",
        majordesc:"Thông tin cơ bản",
        majorNumberofyears:"Số năm học",
        majorawardDegree:"Bằng tốt nghiệp",
        majortrainingGoal:'Định hướng việc làm',
        majorEcharts1:'Thu nhập và cơ hội việc làm',
        majoropenSchool:'Trường có ngành này',
    },
    schoolmajor:{
        title:'LỰA CHỌN ĐIỂM ĐẾN MƠ ƯỚC CỦA BẠN',
        subTitle:'VÀ CHÚNG TÔI SẼ GIÚP BẠN TRÊN HÀNH TRÌNH CHINH PHỤC ĐIỂM ĐẾN ĐÓ',
        searchplaceholder:'Tìm trường',
        regionSelection:"Chọn tỉnh/vùng",
        collegeCharacteristics:"Nằm trong dự án",
        heldByCollegesAndUniversities:"Loại trường",
        educationalLevel:"Bậc học",
        typeOfInstitution:"Định hướng đào tạo",
        conditionSelected:"Các mục đã chọn",
        clearall:"Xóa hết",
        Atotalof:"Tổng cộng có",
        institutionswerefound:"trường đã được tìm thấy",
        column1:"Tên trường",
        column2:"Xếp hạng trường",
        column3:"Địa điểm",
        column4:"Cơ quan phụ trách",
        column5:"Loại hình trường học",
        column:['Tên trường','Xếp hạng trường','Địa điểm','Cơ quan phụ trách','Loại hình trường học'],
        Rankingofsoftscience: 'Xếp hạng theo Shanghai Ranking:',
        WuShulianrank: 'Xếp hạng theo Wu Shulian Ranking:',

    },
    schoolMajorDetails:{
        educationName:"Loại hình trường học",
        levelName:'Bậc học',
        subjection:'Cơ quan phụ trách',
        foundingYear:'Năm thành lập',
        rkRanking:'Xếp hạng theo Shanghai Ranking',
        wslRanking:'Xếp hạng theo Wu Shulian Ranking',
        IntroductionToCollegesAndUniversities:'Thông tin giới thiệu về trường',
        EnrollmentBrochure:'Tờ rơi tuyển sinh',
        xuexiaogaikuang:'Tổng quan về trường',
        Introduction:"Giới thiệu",
        Admissionregulations:'Quy định nhập học',
        masterNumber:"Số chương trình Thạc sĩ",
        doctorNumber:"Số chương trình Tiến sĩ",
        // rate:"Tỉ lệ nhập học sau đại học",
        boyGirl:"Tỉ lệ nam/nữ sinh viên",
        vietnamCityName:"Địa điểm",
        www:"Trang web chính thức",
        telephone:"Số điện thoại tuyển sinh",
        email:"Email",
        vietnamAddress:"Địa chị",
        vietnamSpecialty:"Chuyên ngành trọng điểm quốc gia",
        majorLevelKey:"Xếp hạng giáo dục",
        majorLevelValue:"Mô tả: Top 2% = A+; Top 2% - 5% = A; Top 5% - 10% = A-; Top 10% - 20% = B+; Top 20% - 30% = B; Top 30% - 40% = B-; Top 40% - 50% = C+; Top 50% - 60% = C; Top 60% - 70% = C-",
        schoolName:"Tên trường",
        schoolMajor:"Chuyên khoa",
        schoolMajorName:"Ngành học",
        Rating:"Xếp hạng",
    },
    scholarship:{
        title:'Học bổng Trung Quốc 2024 - Cẩm nang cho Sinh viên quốc tế',
        subTitlel1:'Địa chỉ:',
        subTitlel2:'Đại học: ',
        tab:'Xếp hạng：',
        downFile:"Xem chi tiết",
        box1title:'Đại học Bách Khoa Bắc Kinh (BIT) ',
        box1value1:"Bắc Kinh ",
        box1value2:'Đại học: Lên đến 100% học phí, 100% ký túc xá và trợ cấp 2.500 tệ/tháng',
        box1value3:"Thạc sĩ: Lên đến 100% học phí, 100% ký túc xá và trợ cấp 2.500 tệ/tháng",
        box2title:'Đại học Chiết Giang',
        box2value1:"Hàng Châu, Chiết Giang",
        box2value2:'Đại học: 20.000 tệ cho một năm',
        box2value3:"Thạc sĩ: Lên đến 100% học phí, 100% ký túc xá và trợ cấp hàng tháng ",
        box3title:'Đại học Công nghiệp Hà Nam',
        box3value1:"Trịnh Châu, Hà Nam",
        box3value2:'Đại học: 100% học phí và ký túc xá ',
        box3value3:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box4title:'Đại học Công nghiệp Bắc Kinh',
        box4value1:"Bắc Kinh ",
        box4value2:'Đại học: Miễn học phí năm đầu ',
        box4value3:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box5title:'Đại học Giao Thông Tây An',
        box5value1:"Tây An, Thiểm Tây",
        box5value2:'Đại học: Lên đến 100% học phí và trợ cấp sinh hoạt trong 10 tháng ',
        box5value3:"Thạc sĩ: 100% học phí, ký túc xá, và trợ cấp hàng tháng",
        box6title:'Đại học Ôn Châu',
        box6value1:"Ôn Châu, Chiết Giang",
        box6value2:'Đại học: Lên đến 20.000 tệ/năm',
        box6value3:"Thạc sĩ: 100% học phí, 100% ký túc xá và trợ cấp hàng tháng",
        box7title:'Đại học Sư Phạm Quảng Tây ',
        box7value1:"Quế Lâm, Quảng Tây",
        box7value2:'Đại học: Miễn 33% học phí ',
        box7value3:"Thạc sĩ: 100% học phí, kỹ túc xá và trợ cấp hàng tháng",
        box8title:'Đại học Tài chính Kinh tế Trung Ương',
        box8value1:"Bắc Kinh ",
        box8value2:'Đại học: Miễn học phí năm đầu ',
        box8value3:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box9title:'Học viện Nghệ Thuật Nam Kinh',
        box9value1:"Nam Kinh, Giang Tô",
        box9value2:'Đại học: Miễn 100% học phí, ký túc xá và trợ cấp hàng tháng',
        box9value3:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
        box9tab:"Top 9 Art School",
        box10title:'Đại học Công nghiệp Tây Bắc',
        box10value1:"Tây An, Thiểm Tây",
        box10value2:'Miễn 100% học phí, ký túc xá và trợ cấp hàng tháng ',
        box10value3:"Thạc sĩ: 100% học phí, ký túc xá và trợ cấp hàng tháng",
    },
    Newsone:`<div style="color: #1A1A1A; padding: 0rem 18rem 2.5rem;" >
        <div style="padding: 4rem 0 0;font-weight: 700;font-size: 1.6rem;text-align: left;">
            KHAI TRƯƠNG CÔNG TY EIC EDUCATION VIỆT NAM
        </div>
        <div style="border: 0.15rem solid #F5AA3B;margin: 1rem 0;"></div>
        <div style="font-weight: 400;font-size: 0.9rem;color: #767676;text-align: left;">
            <span style="margin-right: 1.5rem;">2024-07-15</span>
            <span>EIC Education Việt Nam</span>
        </div>
        <div style="padding: 1.5rem 0;">
            Ngày 28 tháng 11 năm 2023, Công ty EIC Education Việt Nam đã chính thức thành lập văn phòng tại Hà Nội, Việt Nam. Thừa hưởng các phương pháp giáo dục tiên tiến và giá trị cốt lõi của Tập đoàn EIC Education, EIC Education Việt Nam cam kết cung cấp các dịch vụ giáo dục quốc tế chất lượng cao và đáng tin cậy cho học sinh Việt Nam, tiếp tục xây dựng cũng như đóng vai trò là cầu nối trao đổi giáo dục giữa các quốc gia và thúc đẩy trao đổi nhân tài quốc tế. Năm 2023, Trung Quốc và Việt Nam kỷ niệm 73 năm thiết lập quan hệ ngoại giao, là tiền đề tiếp tục thúc đẩy xây dựng dự án “Một Vành đai - Một Con đường”, thắt chặt tình hữu nghị giữa Trung Quốc và Việt Nam lên một tầm cao mới. Sự thành lập Công ty EIC Education Việt Nam chính là minh chứng rõ ràng nhất cho bước phát triển này trong lĩnh vực giáo dục.
        </div>
        <div style="text-align: center;">
            <div>
                <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/b3a32ad1642b4dd2b5eeb2cb5e9b0474">
                <p style="font-weight: 600;font-size: 0.8rem;">Lễ cắt băng khánh thành</p>
            </div>
        </div>
        <div style="padding: 1.5rem 0;">
            Tại lễ khai mạc có sự góp mặt của đại diện các trường đại học Trung Quốc danh tiếng như Đại học Công nghiệp Tây Bắc, Đại học Bách khoa Bắc Kinh, Đại học Sư phạm Quảng Tây, Đại học Công nghệ Hà Nam, Đại học Tài chính và Kinh tế Trung ương, Đại học Tài chính Chiết Giang và Đại học Vịnh Bắc đã đến và trao đổi về mong muốn tăng cường lực lượng sinh viên quốc tế chất lượng cao. Điều này không chỉ thể hiện nền tảng hợp tác giáo dục sâu sắc giữa Trung Quốc và Việt Nam mà còn mang đến nhiều lựa chọn du học chất lượng cao hơn cho sinh viên Việt Nam.
        </div>
        <div>
            <div style="text-align: center;margin-bottom: 1.5rem;">
                <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/beebee9cf42642d2b4a9510087042e69">
                <p style="font-weight: 600;font-size: 0.8rem;margin:0 auto;">Hình ảnh khách mời trong buổi lễ</p>
            </div>
            <div style="text-align: center;">
                <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/9305ccbc50f548ef8b3502d8ee401ed8">
                <p style="font-weight: 600;font-size: 0.8rem;margin:0 auto;">Hình ảnh khách mời trong buổi lễ</p>
            </div>
        </div>
        <div style="padding: 1.5rem 0;">
            Trong buổi lễ khai trương, ông Kim Nhiễm, Giám đốc điều hành EIC Education, ông Lý Tử Vỹ, Phó Giám đốc Văn phòng Hợp tác và Giao lưu Quốc tế Trường Đại học Công nghiệp Tây Bắc và ông Bùi Kiên Cường, Phó Hiệu trưởng Trường Đại học Sư phạm Hà Nội 2 đã tham dự và có những bài phát biểu bày tỏ những mong muốn cho triển vọng phát triển giáo dục và hợp tác thành công trong tương lai. Ngoài ra, ông Tạ Phi, Phó Giám đốc Trung tâm Sinh viên Quốc tế thuộc Đại học Bách khoa Bắc Kinh, Giám đốc Văn phòng Hợp tác và Trao đổi Quốc tế của Đại học Sư phạm Quảng Tây và Trưởng khoa Giáo dục Quốc tế của Đại học Công nghệ Hà Nam cũng góp mặt qua hình thức trực tuyến và gửi lời chúc mừng đến EIC Education Việt Nam.
        </div>
        <div style="text-align: center;">
                <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/70f92bc54afd445f9916d20922ba8218">
                <p style="font-weight: 600;font-size: 0.8rem;">Các khách mời tham quan văn phòng EIC Education Việt Nam</p>
            </div>
        <div style="padding: 1.5rem 0;">
            Với việc chính thức thành lập Công ty EIC Education Việt Nam, Tập đoàn EIC Education tin rằng sự hợp tác giữa Trung Quốc và Việt Nam trong lĩnh vực giáo dục sẽ ngày càng chặt chẽ hơn trong tương lai, mang đến nhiều cơ hội hơn cho sự nuôi dưỡng và phát triển nhân tài giữa hai nước.
        </div>
    </div>`,
    Newstwo:`<div style="color: #1A1A1A; padding: 0rem 18rem 2.5rem;" >
        <div style="padding: 4rem 0 0;font-weight: 700;font-size: 1.6rem;text-align: left;">
          EIC EDUCATION TỔ CHỨC THÀNH CÔNG DIỄN ĐÀN TẠI HỘI NGHỊ THƯỜNG NIÊN GIÁO
        </div>
        <div style="border: 0.15rem solid #F5AA3B;margin: 1rem 0;"></div>
        <div style="font-weight: 400;font-size: 0.9rem;color: #767676;text-align: left;">
            <span style="margin-right: 1.5rem;">2024-07-15</span>
            <span>EIC Education Việt Nam</span>
        </div>
        <div style="padding: 1.5rem 0;">
          Vào ngày 27 tháng 10 năm 2023, diễn đàn "Sáng kiến bồi dưỡng nhân tài học tập ở Trung Quốc thời đại mới - Góc nhìn từ tổ chức và doanh nghiệp" do Hiệp hội trao đổi giáo dục quốc tế Trung Quốc tổ chức và EIC Education chủ trì đã diễn ra thành công tốt đẹp tại Trung tâm Hội nghị Quốc gia Bắc Kinh. Hơn 100 đại diện từ hơn 50 trường đại học trên cả nước và các doanh nghiệp lớn ở nước ngoài đã cùng nhau thảo luận về các mô hình và phương pháp mới trong đào tạo nhân tài quốc tế, đặc biệt trong bối cảnh tình hình thế giới hiện nay. Các vấn đề được thảo luận xoay quanh giáo dục đại học, thạc sĩ, tiến sĩ, dạy nghề, và các đề án liên kết giữa các cơ sở giáo dục và doanh nghiệp.
        </div>
        <div style="text-align: center;">
            <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/8489f50c0e59431e8c6081bc5b25e8e2">
            <p style="font-weight: 600;font-size: 0.9rem;text-align: center;">Toàn thể khách mời và đại biểu tham gia diễn đàn</p>
          </div>
        <div style="padding: 1.5rem 0;">
          Các chuyên gia và đại diện của Đại học Chiết Giang, Đại học Công nghiệp Tây Bắc, Đại học Sư phạm Quảng Tây, Đại học Ôn Châu và Trường Cao đẳng Kỹ thuật Nông Lâm nghiệp Giang Tô cũng đã có những lời phát biểu và đóng góp ý tưởng tại cuộc họp. Bên cạnh đó, đại diện cho các công ty Trung Quốc có vốn đầu tư tại nước ngoài đã chia sẻ kinh nghiệm thực tế của họ về thị trường lao động và đưa ra các đề xuất chi tiết hơn về định hướng và nhu cầu đào tạo nhân tài quốc tế ở các trường cao đẳng và đại học.
        </div>
        <div style="text-align: center;">
                <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/8b0a3780458f4283b18224cb53411746">
                <p style="font-weight: 600;font-size: 0.9rem;text-align: center;">Ông Lưu Bảo Lợi, Tổng thư ký điều hành Ban tổ chức Tuần lễ trao đổi giáo dục Trung Quốc - ASEAN</p>
        </div>
        <div style="text-align: center;">
                <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/875a9b5cffef433f902c9f211a23b4c4">
                <p style="font-weight: 600;font-size: 0.9rem;text-align: center;">Bà Quách Bội, Giám đốc điều hành Tập đoàn EIC Education</p>
        </div>
        <div style="padding: 1.5rem 0;">
          Các chuyên gia và đại diện của Đại học Chiết Giang, Đại học Công nghiệp Tây Bắc, Đại học Sư phạm Quảng Tây, Đại học Ôn Châu và Trường Cao đẳng Kỹ thuật Nông Lâm nghiệp Giang Tô cũng đã có những lời phát biểu và đóng góp ý tưởng tại cuộc họp. Bên cạnh đó, đại diện cho các công ty Trung Quốc có vốn đầu tư tại nước ngoài đã chia sẻ kinh nghiệm thực tế của họ về thị trường lao động và đưa ra các đề xuất chi tiết hơn về định hướng và nhu cầu đào tạo nhân tài quốc tế ở các trường cao đẳng và đại học.
        </div>
        <div style="text-align: center;">
                <img style="width:40rem;" src="https://lximg.eiceducation.com.cn/img/2f26aae06e174c01a037f924b687592f">
                <p style="font-weight: 600;font-size: 0.9rem;text-align: center;">Diễn đàn kết thúc thành công tốt đẹp</p>
        </div>
        <div style="padding: 1.5rem 0;">
          Việc tổ chức thành công diễn đàn này đã thể hiện năng lực và uy tín của Tập đoàn EIC Education trong lĩnh vực giáo dục quốc tế và cho thấy một mạng lưới hợp tác bền vững với các tổ chức giáo dục, trường học và hệ thống doanh nghiệp của EIC Education.
        </div>
    </div>`,
    Newsthree:`<div style="color: #1A1A1A; padding: 0rem 18rem 2.5rem; font-size: 0.9rem;" >
        <div style="padding: 4rem 0 0;font-weight: 700;font-size: 1.6rem;text-align: left;">
            7 LÝ DO DU HỌC TRUNG QUỐC: NỀN GIÁO DỤC VÀ KINH TẾ 
        </div>
        <div style="border: 0.15rem solid #F5AA3B;margin: 1rem 0;"></div>
        <div style="font-weight: 400;font-size: 0.9rem;color: #767676;text-align: left;">
            <span style="margin-right: 1.5rem;">2024-07-15</span>
            <span>EIC Education Việt Nam</span>
        </div>
        <div style="padding: 1.5rem 0;">
            Vì sao <b>du học Trung Quốc</b>ngày càng trở nên phổ biến, đặc biệt là với học sinh Việt Nam chúng ta? Trung Quốc, với sự phát triển vượt bậc về kinh tế và giáo dục, đang trở thành một trong những điểm đến du học hấp dẫn nhất thế giới. Nếu bạn đang cân nhắc một điểm đến du học quốc tế, Trung Quốc chắc chắn là một lựa chọn không thể bỏ qua. Dưới đây là 7 lý do hàng đầu khiến du học Trung Quốc trở thành một quyết định thông minh và đầy tiềm năng cho tương lai của bạn.
        </div> 
        <div style="background: #F2F6F7;padding: 1rem 1.5rem;">
            <div style="font-weight: 700;font-size: 0.9rem;color: #F5AA3B;text-align: left;margin-bottom: 0.5rem;">
                Nội dung bài viết
            </div>
            <div style="font-size: 0.9rem;color: #004163;text-align: left;">
                <p><a href="#new1">1. Chất lượng giáo dục đẳng cấp thế giới</a></p>
                <p><a href="#new2">2. Chi phí học tập và sinh hoạt hợp lý</a> </p>
                <p><a href="#new3">3. Cơ hội nhận học bổng hấp dẫn </a> </p>
                <p><a href="#new4">4. Nền kinh tế phát triển mạnh mẽ và cơ hội việc làm</a> </p>
                <p><a href="#new5"> 5. Trải nghiệm văn hóa phong phú và đa dạng</a> </p>
                <p><a href="#new6">6. Học tiếng Trung – Ngôn ngữ của tương lai</a> </p>
                <p><a href="#new7">7. Chính sách hỗ trợ sinh viên quốc tế</a></p>
            </div>
        </div>
        <div style="font-weight: 600;font-size: 1.4rem;padding: 1.5rem 0 0.5rem;" id="new1">
            1. Chất lượng giáo dục đẳng cấp thế giới
        </div>
        <div style="width:60rem;margin: 1rem auto; font-size: 0.9rem;">
            <table border="1" cellspacing="0" cellpadding="0">
                <tr>
                    <td style="width: 17.5rem; padding: 1.4rem 0;text-align: center;">Đại học</td>
                    <td style="width: 13.4rem; padding: 1.2rem 0;text-align: center;">QS World University Ranking</td>
                    <td style="width: 13.4rem; padding: 1.2rem 0;text-align: center;">THE World University Ranking</td>
                    <td style="width: 13.4rem; padding: 1.2rem 0;text-align: center;">USNews Best Global Universities Ranking</td>
                </tr>
                <tr>
                    <td style="width: 17.5rem; padding: 1.4rem 0;text-align: center;">Đại học Bắc Kinh</td>
                    <td style="width: 13.4rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">17</td>
                    <td style="width: 13.4rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">14</td>
                    <td style="width: 13.4rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">39</td>
                </tr>
                <tr>
                    <td style="width: 17.5rem; padding: 1.4rem 0;text-align: center;">Đại học Thanh Hoa</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;">25</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;">12</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;">23</td>
                </tr>
                <tr>
                    <td style="width: 17.5rem; padding: 1.4rem 0;text-align: center;">Đại học Chiết Giang</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">44</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">55</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">93</td>
                </tr>
                <tr>
                    <td style="width: 17.5rem; padding: 1.4rem 0;text-align: center;">Đại học Phúc Đán</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;">50</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;">44</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;">116</td>
                </tr>
                <tr>
                    <td style="width: 17.5rem; padding: 1.4rem 0;text-align: center;">Đại học Giao thông Thượng Hải</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">52</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">43</td>
                    <td style="width: 15.8rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">89</td>
                </tr>
            </table>
        </div>
        <div>
            <b> Chương trình đào tạo đa dạng:</b> Từ khoa học kỹ thuật, y học, kinh tế, quản lý, đến nghệ thuật và nhân văn, các trường đại học Trung Quốc cung cấp đa dạng các chương trình học, phù hợp với nhiều lĩnh vực chuyên môn và sở thích của sinh viên.  <br/>
            <b>Đội ngũ giảng viên xuất sắc:</b> Nhiều giảng viên có kinh nghiệm quốc tế, từng học tập và giảng dạy tại các trường đại học hàng đầu thế giới, đảm bảo chất lượng giảng dạy và nghiên cứu.
        </div>
        <div style="font-weight: 600;font-size: 1.4rem;padding: 1.5rem 0 1rem;" id="new2">2. Chi phí học tập và sinh hoạt hợp lý 
        </div>
        <div>
            So với các quốc gia phát triển khác, chi phí du học Trung Quốc khá hợp lý, giúp nhiều sinh viên quốc tế có thể dễ dàng tiếp cận.
            <br>
            <b> Học phí:</b> Tùy thuộc vào ngành học và trường đại học, học phí dao động từ 2,000 đến 5,000 USD mỗi năm, thấp hơn nhiều so với các quốc gia du học khác.
        </div>
        <div style="width:60rem;margin: 1rem auto; ">
            <div><b>Học phí:</b> Tùy thuộc vào ngành học và trường đại học, học phí dao động từ 2,000 đến 5,000 USD mỗi năm, thấp hơn nhiều so với các quốc gia du học khác. </div>

            <div style="margin: 1rem auto; font-size: 0.9rem;">
                <table border="1" cellspacing="0" cellpadding="0">
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Quốc gia</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">Học phí trung bình cho du học sinh (USD/năm)</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">Mỹ</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">$20,000 - $60,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Anh</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">$15,000 - $50,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">Úc</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">$15,000 - $35,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Canada</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">$15,000 - $25,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">Nhật Bản</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">$5,000 - $15,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Trung Quốc</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">$2,000 - $5,000</td>
                    </tr>
                </table>
            </div>

            <div><b>Sinh hoạt phí:</b>  Chi phí sinh hoạt bao gồm nhà ở, ăn uống và đi lại khoảng từ 200 USD đến 600 USD mỗi tháng, phụ thuộc vào thành phố và phong cách sống của sinh viên. Chi phí sinh hoạt ở Trung Quốc nhìn chung không quá cao so với tại Việt Nam, và thấp hơn nhiều so với các cuốc gia khác </div>

            <div style="margin: 1rem auto; font-size: 0.9rem;">
                <table border="1" cellspacing="0" cellpadding="0">
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Quốc gia</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">Chi phí sinh hoạt trung bình (USD/năm)</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">Mỹ</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">$10,000 - $25,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Anh</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">$10,000 - $25,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">Úc</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">$10,000 - $20,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Canada</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">$7,000 - $15,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">Nhật Bản</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;background: #F2F6F7;">$5,000 - $10,000</td>
                    </tr>
                    <tr>
                        <td style="width: 22.45rem; padding: 1rem 0;text-align: center;">Trung Quốc</td>
                        <td style="width: 47.55rem; padding: 1rem 0;text-align: center;">$3,000 - $8,000</td>
                    </tr>
                </table>
            </div>

        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1rem 0 1.5rem;" id="new3">
            3. Cơ hội nhận học bổng hấp dẫn
        </div>
        <div>
            Học bổng du học Trung Quốc rất đa dạng dành cho sinh viên quốc tế, từ học bổng chính phủ đến học bổng do các trường đại học và tổ chức tài trợ.  <br>
            <b>Học bổng Chính phủ Trung Quốc (CSC):</b> Hỗ trợ toàn phần hoặc bán phần học phí, sinh hoạt phí và bảo hiểm y tế. <br>
            <b>Học bổng Khổng Tử (CIS):</b> Dành cho những sinh viên học tiếng Trung hoặc các ngành liên quan đến văn hóa Trung Quốc. <br>
            <b>Học bổng của các trường đại học/tỉnh/thành phố: </b>Nhiều trường đại học và tỉnh/thành phố cung cấp học bổng riêng cho sinh viên quốc tế, hỗ trợ từ học phí đến chi phí sinh hoạt. <br>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1rem 0 1.5rem;" id="new4">
            4. Nền kinh tế phát triển mạnh mẽ và cơ hội việc làm
        </div>
        <div>
            Trung Quốc là nền kinh tế lớn thứ hai thế giới, với tốc độ tăng trưởng kinh tế ấn tượng, tạo ra nhiều cơ hội việc làm cho sinh viên du học Trung Quốc sau khi tốt nghiệp.<br>
            <b>Cơ hội việc làm:</b> Nhiều công ty đa quốc gia và doanh nghiệp Trung Quốc liên tục tìm kiếm nhân tài có kiến thức và kinh nghiệm quốc tế. Sinh viên tốt nghiệp có thể tìm được việc làm trong các lĩnh vực như công nghệ, kinh doanh, tài chính, và nhiều ngành khác. <br>
            <b>Kết nối mạng lưới:</b> Du học Trung Quốc giúp bạn xây dựng mạng lưới kết nối rộng lớn, gặp gỡ và làm việc với các chuyên gia và bạn bè quốc tế.<br>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1rem 0 1.5rem;" id="new5">
            5. Trải nghiệm văn hóa phong phú và đa dạng
        </div>
        <div>
            Trung Quốc có nền văn hóa lâu đời và đa dạng, từ lịch sử, nghệ thuật, đến ẩm thực và phong tục tập quán. <br>
            <b>Khám phá văn hóa: </b>Du học tại Trung Quốc, bạn sẽ có cơ hội khám phá các di sản văn hóa như Vạn Lý Trường Thành, Cố Cung, và nhiều di tích lịch sử khác. <br>
            <b>Giao lưu văn hóa: </b>Môi trường học tập đa văn hóa giúp bạn phát triển kỹ năng giao tiếp, hiểu biết sâu sắc hơn về văn hóa và lối sống của người Trung Quốc.<br>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1rem 0 1.5rem;" id="new6">
            6. Học tiếng Trung – Ngôn ngữ của tương lai
        </div>
        <div>
            Tiếng Trung là ngôn ngữ được sử dụng nhiều nhất trên thế giới và ngày càng trở nên quan trọng trong kinh doanh quốc tế. <br>
            <b> Cơ hội học tiếng Trung:</b> Nhiều trường đại học cung cấp các khóa học tiếng Trung cho sinh viên quốc tế, từ cơ bản đến nâng cao, giúp bạn có thể giao tiếp và làm việc hiệu quả trong môi trường quốc tế.<br>
            <b> Lợi thế cạnh tranh:</b> Thành thạo tiếng Trung là một lợi thế lớn trong thị trường việc làm toàn cầu, mở ra nhiều cơ hội nghề nghiệp hấp dẫn.
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1rem 0 1.5rem;" id="new7">
            7. Chính sách hỗ trợ sinh viên quốc tế
        </div>
        <div>
            Chính phủ và các trường đại học Trung Quốc có nhiều chính sách hỗ trợ sinh viên quốc tế, từ thủ tục nhập học, xin visa, đến các dịch vụ hỗ trợ đời sống hàng ngày. <br>
            <b>Hỗ trợ hồ sơ xin visa du học Trung Quốc:</b> Quy trình xin visa du học (X1 Visa) được hỗ trợ bởi các trường đại học và Đại sứ quán Trung Quốc, giúp sinh viên dễ dàng và nhanh chóng hoàn thành các thủ tục cần thiết. <br>
            <b>Dịch vụ hỗ trợ sinh viên: </b>Các trường đại học thường có văn phòng hỗ trợ sinh viên quốc tế, cung cấp các dịch vụ tư vấn, hỗ trợ học tập và đời sống, giúp sinh viên nhanh chóng thích nghi với cuộc sống mới.
        </div>
        <div style="padding: 2.5rem 0;">
            <b>Du học Trung Quốc không</b> chỉ mang đến cơ hội học tập chất lượng cao mà còn mở ra cánh cửa khám phá một nền văn hóa đa dạng và phong phú. Với chi phí hợp lý, nhiều học bổng hấp dẫn và môi trường học tập năng động, Trung Quốc là điểm đến lý tưởng cho những ai mong muốn chinh phục tri thức và phát triển sự nghiệp trong môi trường quốc tế. Hãy cân nhắc Trung Quốc trong kế hoạch du học của bạn và bắt đầu hành trình khám phá thế giới ngay hôm nay!
        </div>
    </div>`,
    Newsfore:`<div style="color: #1A1A1A; padding: 0rem 18rem 2.5rem; font-size: 0.9rem;" >
        <div style="padding: 4rem 0 0;font-weight: 700;font-size: 1.6rem;text-align: left;">
            Hỏi & Đáp: Du học Trung Quốc - Tất tần tật về bài kiểm tra trình độ đầu vào mới
        </div>
        <div style="border: 0.15rem solid #F5AA3B;margin: 1rem 0;"></div>
        <div style="font-weight: 400;font-size: 0.9rem;color: #767676;text-align: left;">
            <span style="margin-right: 1.5rem;">2024-07-15</span>
            <span>EIC Education Việt Nam</span>
        </div>
        <div style="padding: 1.5rem 0;">
            Tính đến ngày 22 tháng 5 năm nay, chính thức có thông báo về việc du học sinh sẽ phải làm bài <b>kiểm tra trình độ học vấn đầu vào</b> từ các trường đại học như Đại học Bắc Kinh, Đại học Phúc Đán, Đại học Khoa học và Công nghệ Hoa Trung, Đại học Thiên Tân, Đại học Khoa học và Công nghệ Bắc Kinh, Đại học Kinh tế và Thương mại Đối ngoại, Đại học Thượng Hải, Đại học Trung Sơn, Đại học Ngoại ngữ Thượng Hải và Đại học Nhân dân Trung Quốc. Vì vậy, EIC Education Việt Nam đã tổng hợp 5 câu hỏi phổ biến nhất về bài <b>Kiểm tra trình độ học vấn đầu vào</b> trong quá trình ứng tuyển du học Trung Quốc bậc đại học để giúp các bạn lên kế hoạch ứng tuyển hợp lý và chuẩn bị hiệu quả cho kỳ thi.
        </div> 
        <div style="background: #F2F6F7;padding: 1rem 1.5rem;">
            <div style="font-weight: 700;font-size: 0.9rem;color: #F5AA3B;text-align: left;padding-bottom: 0.5rem;">
                Nội dung bài viết
            </div>
            <div style="font-size: 0.9rem;color: #004163;text-align: left;">
                <p style="margin: 0;padding: 0;">
                    <a href="#new1">1. Câu hỏi 1: Tôi có cần hoàn thành bài kiểm tra gì trong quá trình ứng tuyển vào các trường đại học Trung Quốc không?</a>
                </p>
                <p style="margin: 0;padding: 0;">
                    <a href="#new2">2. Câu hỏi 2: Bài Kiểm tra trình độ học vấn đầu vào sẽ bao gồm các môn và nội dung thi gì?</a><br>
                    <a href="#new2_1"> • 2.1. Đối với các chương trình giảng dạy bằng tiếng Trung</a><br>
                    <a href="#new2_2"> •  2.2. Chương trình dạy bằng tiếng Anh</a>
                </p>
                <p  style="margin: 0;padding: 0;">
                    <a href="#new3"> 3. Câu hỏi 3: Tôi có cần đến trường đại học đã ứng tuyển ở Trung Quốc để thực hiện bài Kiểm tra trình độ học vấn đầu vào? </a> </p>
                <p style="margin: 0;padding: 0;">
                    <a href="#new4">4. Câu hỏi 4: Lịch trình của bài Kiểm tra trình độ học vấn đầu vào là thế nào?</a> </p>
                <p style="margin: 0;padding: 0;">
                    <a href="#new5"> 5. Câu hỏi 5: Làm thế nào để chuẩn bị tốt nhất cho bài Kiểm tra trình độ học vấn đầu vào của các trường đại học Trung Quốc?</a><br>
                    <a href="#new5_1"> • 5.1. Lập kế hoạch chuẩn bị</a><br>
                    <a href="#new5_2"> • 5.2. Xây dựng chiến lược ôn thi</a><br>
                    <a href="#new5_3"> • 5.3. Tham khảo tư vấn từ chuyên gia</a><br>
                    <a href="#new5_4"> • 5.4. Giữ tâm lý tốt, thoải mái</a><br>
                 </p>
                <p style="margin: 0;padding: 0;">
                    <a href="#new6">6. Học tiếng Trung – Ngôn ngữ của tương lai</a> 
                </p>
                <p style="margin: 0;padding: 0;">
                    <a href="#new7">7. Chính sách hỗ trợ sinh viên quốc tế</a>
                </p>
            </div>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1.5rem 0 1rem;" id="new1">
            1. Câu hỏi 1: Tôi có cần hoàn thành bài kiểm tra gì trong quá trình ứng tuyển vào các trường đại học Trung Quốc không?
        </div>
        <div>
            Theo thông báo của Hội đồng Quản lý Học bổng Quốc gia Trung Quốc về việc tổ chức kiểm tra thử trình độ học vấn cho tân sinh viên du học Trung Quốc, tất cả ứng viên bậc đại học có thông báo nhập học tạm thời năm 2024 đều phải tham gia bài Kiểm tra trình độ học vấn đầu vào.
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 2.5rem 0 1rem;" id="new2">
            2. Câu hỏi 2: Bài Kiểm tra trình độ học vấn đầu vào sẽ bao gồm các môn và nội dung thi gì?
        </div>
        <div>
            Sinh viên đăng ký chương trình dạy bằng tiếng Trung phải tham gia bài kiểm tra Tiếng Trung chuyên ngành, chọn bài kiểm tra Tiếng Trung văn khoa, Tiếng Trung kinh tế thương mại, Tiếng Trung khoa học kỹ thuật hoặc Tiếng Trung y học tương ứng theo chuyên ngành.
            <div id="new2_1">
                <b>2.1. Đối với các chương trình giảng dạy bằng tiếng Trung</b> 
            </div>
            <div>
                Sinh viên đăng ký chương trình dạy bằng tiếng Trung phải tham gia bài kiểm tra Tiếng Trung chuyên ngành, chọn bài kiểm tra Tiếng Trung văn khoa, Tiếng Trung kinh tế thương mại, Tiếng Trung khoa học kỹ thuật hoặc Tiếng Trung y học tương ứng theo chuyên ngành.
            </div>
            <div>
                ● Sinh viên học các chuyên ngành văn khoa (văn học, luật, triết học, lịch sử, giáo dục, nghệ thuật) và y học cổ truyền Trung Quốc (hoặc y dược cổ truyền Trung Quốc) chỉ tham gia bài kiểm tra Tiếng Trung văn khoa.
            </div>
            <div>
                ● Sinh viên học các chuyên ngành kinh tế và quản lý cần phải tham gia bài kiểm tra toán học.
            </div>
            <div>
                ● Sinh viên học các chuyên ngành khoa học tự nhiên, kỹ thuật, nông nghiệp cần tham gia bài kiểm tra toán học và dựa vào yêu cầu của chuyên ngành mà chọn một trong hai môn vật lý hoặc hóa học.
            </div>
            <div>
                ● Sinh viên học các chuyên ngành y học phương Tây (dược phương Tây) phải tham gia bài kiểm tra toán học và hóa học.
            </div>
            <div id="new2_2">
                <b>2.2. Chương trình dạy bằng tiếng Anh</b> 
            </div>
            <div>
                Sinh viên đăng ký chương trình dạy bằng tiếng Anh, ngoại trừ các chuyên ngành văn học, luật, triết học, lịch sử, giáo dục, nghệ thuật và y học cổ truyền Trung Quốc, cần tham gia các bài kiểm tra môn khoa học tự nhiên tương ứng là toán học, vật lý và hóa học.
            </div>
            <div>
                ● Sinh viên học các chuyên ngành kinh tế và quản lý tham gia bài kiểm tra toán học.
            </div>
            <div>
                ● Sinh viên học các chuyên ngành khoa học tự nhiên, kỹ thuật và nông nghiệp cần tham gia bài kiểm tra toán học và dựa vào yêu cầu của chuyên ngành mà chọn một trong hai môn vật lý hoặc hóa học.
            </div>
            <div>
                ● Sinh viên học các chuyên ngành y học phương Tây (dược phương Tây) phải tham gia bài kiểm tra toán học và hóa học.
            </div>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 2.5rem 0 1rem;" id="new3">
            Câu hỏi 3: Tôi có cần đến trường đại học đã ứng tuyển ở Trung Quốc để thực hiện bài Kiểm tra trình độ học vấn đầu vào?
        </div>
        <div>
            Thí sinh sẽ tham gia thi trực tuyến tại nhà. Các thí sinh sẽ cần chuẩn bị một máy tính có kết nối internet và camera, cùng ít nhất một thiết bị khác (điện thoại hoặc máy tính bảng) để giám sát môi trường thi. Bài kiểm tra Tiếng Trung chuyên ngành bao gồm phần nghe, vì vậy thí sinh cũng cần chuẩn bị thêm tai nghe. 
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 2.5rem 0 1rem;" id="new4">
            4. Câu hỏi 4: Lịch trình của bài Kiểm tra trình độ học vấn đầu vào là thế nào?
        </div>
        <div>
            Lịch thi của đợt ứng tuyển đại học Trung Quốc năm 2024 vừa qua như sau: <br>
            <b>Thi mô phỏng:</b>Mỗi thí sinh cần thực hiện thi mô phỏng 1 lần trước lần thi chính thức để làm quen với nền tảng và thiết bị trong bài thi. Nội dung và kết quả bài kiểm tra sẽ không liên quan đến bài thi chính thức. Lịch thi mô phỏng: từ ngày 22/5 - 24/5. <br>
            <b>Thi chính thức:</b> Tùy vào thời gian cụ thể quy định bởi các trường đại học, kỳ thi chính thức sẽ được chia làm 2 lần:  <br>
            <div>
                <b>● Lần 1:</b> 25/05 - 26/05
            </div>
            <div>
                <b>● Lần 2:</b> 01/06 - 02/06
            </div>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 2.5rem 0 1rem; line-height: 1.6rem;" id="new5">
            5. Câu hỏi 5: Làm thế nào để chuẩn bị tốt nhất cho bài Kiểm tra trình độ học vấn đầu vào của các trường đại học Trung Quốc?
        </div>
        <div >
            Dưới đây là một số lời khuyên của EIC Education Việt Nam dành cho những tân sinh viên có nguyện vọng ứng tuyển vào các trường đại học Trung Quốc về bài Kiểm tra trình độ học vấn:<br>
            <b id="new5_1">5.1. Lập kế hoạch chuẩn bị</b><br>
            <b>●  Đánh giá khả năng kiến thức của bản thân: </b>Đầu tiên cần xác định các môn học mà bạn cần phải làm bài kiểm tra theo chuyên ngành đã ứng tuyển. Sau đó, hãy đánh giá lại kiến thức của mình trong các môn thi liên quan, xác định xem phần nào yếu hơn, cần bổ sung kiến thức.  <br>
            <b>●  Phân bổ thời gian:</b>Sắp xếp thời gian mỗi ngày hợp lý, dành thời gian ôn tập hàng ngày theo mức độ ưu tiên của từng môn thi đã xác định và vốn kiến thức của bản thân.  <br>
            <b id="new5_2">5.2. Xây dựng chiến lược ôn thi</b><br>
            <b>●  Tham gia thi mô phỏng:</b>Bạn nên thực hiện một vài kỳ thi mô phỏng trước, trả lời câu hỏi theo đúng thời gian thi và làm quen với nhịp độ bài thi.  <br>
            <b>●  Tổng hợp các lỗi thường gặp:</b>Ghi chép và thống kê các lỗi thường làm sai, tìm ra các điểm dễ sai, luyện tập nhiều lần để tránh lặp lại các lỗi đó.   <br>
            <b id="new5_3">5.3. Tham khảo tư vấn từ chuyên gia </b><br>
            <div>
                Các ứng viên cũng có thể tìm đến một tổ chức giáo dục, đào tạo chuyên nghiệp hoặc giáo viên để có được hướng dẫn và tư vấn về học thuật, giúp đảm bảo tính hệ thống và hiệu quả của việc học tập. Người hướng dẫn có thể giúp các bạn học sinh sinh viên xây dựng kế hoạch học tập có mục tiêu dựa trên tình hình thực tế của bản thân, giúp các bạn nắm vững một cách có hệ thống các kiến thức và câu hỏi thi, cải thiện hiệu quả quá trình ôn thi trong thời gian ngắn. 
            </div>
            <b id="new5_4">5.4. Giữ tâm lý tốt, thoải mái </b><br>
            <div>
                Quan trọng nhất, các bạn cần lưu ý duy trì thời gian nghỉ ngơi thường xuyên, đảm bảo mỗi ngày có đủ giấc ngủ để duy trì trạng thái thể chất và tinh thần tốt nhất, tập thể dục thường xuyên, có các hình thức thư giãn và giảm áp lực trong thời gian ôn thi. 
            </div>
        </div>
        <div style="padding-top: 2.5rem;">
            Thông qua các câu trả lời trên, EIC Education Việt Nam hi vọng các tân sinh viên chuẩn bị ứng tuyển vào các trường đại học Trung Quốc nắm rõ hơn tầm quan trọng của bài <b>Kiểm tra trình độ học vấn đầu vào.</b> Với xu hướng mới của các trường đại học Trung Quốc, từ các mùa ứng tuyển tiếp theo, việc sử dụng bài Kiểm tra này là hình thức để đánh giá chính xác hơn khả năng của sinh viên sẽ được sử dụng rộng rãi hơn. Vì vậy, đặc biệt với các ứng viên có nền tảng kiến thức chưa thực sự vững chắc, việc lập kế hoạch chuẩn bị và ôn tập cho bài Kiểm tra càng trở nên cấp thiết. Hãy chuẩn bị cho mình những kiến thức thật vững vàng để tự tin trên con đường chinh phục học bổng Trung Quốc danh giá nhé!
        </div>
    </div>`,
    Newsfive:`<div style="color: #1A1A1A; padding: 0rem 18rem 2.5rem;" >
        <div style="padding: 4rem 0 0;font-weight: 700;font-size: 1.6rem;text-align: left;">
            Học tiếng Trung cho người mới bắt đầu nên bắt đầu từ đâu?
        </div>
        <div style="border: 0.15rem solid #F5AA3B;margin: 1rem 0;"></div>
        <div style="font-weight: 400;font-size: 0.9rem;color: #767676;text-align: left;">
            <span style="margin-right: 1.5rem;">2024-07-15</span>
            <span>EIC Education Việt Nam</span>
        </div>
        <div style="padding: 1.5rem 0;">
            <b>Học tiếng Trung cho người mới bắt đầu</b> nên bắt đầu từ đâu? Đây là một là một vấn đề luôn thường trực trong những người có nguyện vọng theo đuổi ngôn ngữ khó nhất hành tình này. Vậy hãy để <b>EIC Education Việt Nam</b> mách bạn lộ trình học tiếng Trung cho người mới ban đầu đầy hiệu quả thông qua bài viết dưới đây nhé. 
        </div> 
        <div style="background: #F2F6F7;padding: 1rem 1.5rem;">
            <div style="font-weight: 700;font-size: 0.9rem;color: #F5AA3B;text-align: left;padding-bottom: 0.5rem;">
                Nội dung bài viết
            </div>
            <div style="font-size: 0.9rem;color: #004163;text-align: left;">
                <p style="margin: 0;padding: 0;">
                    <a href="#new1">1. Lợi ích của việc học tiếng Trung</a>
                </p>
                <p style="margin: 0;padding: 0;">
                    <a href="#new2">2. Lộ trình học tiếng Trung cho người mới bắt đầu</a><br>
                    <a href="#new2_1"> • 2.1. Ai nên học tiếng Trung cho người mới bắt đầu?</a><br>
                    <a href="#new2_2"> •  2.2. Học bảng chữ cái/cách phát âm </a><br>
                    <a href="#new2_3"> •  2.3. Học quy tắc viết chữ Hán </a><br>
                    <a href="#new2_4"> •  2.4. Làm quen các bộ thủ tiếng Trung </a><br>
                    <a href="#new2_5"> •  2.5. Học kết hợp 4 kỹ năng nghe - nói - đọc - viết </a><br>
                    <a href="#new2_6"> •  2.6. Học ngữ pháp </a><br>
                    <a href="#new2_7"> •  2.7. Xây dựng vốn từ vựng</a><br>
                    <a href="#new2_8"> •  2.8. Ôn tập thường xuyên</a><br>
                </p>
                <p  style="margin: 0;padding: 0;">
                    <a href="#new3"> 3. Học tiếng Trung cho người mới bắt đầu ở đâu uy tín, chất lượng?</a> 
                </p>
            </div>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1.5rem 0 1rem;" id="new1">
            1. Lợi ích của việc học tiếng Trung
        </div>
        <div>
            Ngoài tiếng Anh là ngôn ngữ toàn cầu được mọi người theo học ra, Tiếng Trung cũng là ngôn ngữ đang được rất nhiều người săn đón. Vậy điều gì khiến mọi người lại chuộng ngôn ngữ khó nhằn này?
            <div>
                ● Với trình độ HSK 4 trở lên, cơ hội sẽ rộng mở với bạn với học bổng du học bậc Đại học tại các trường TOP đầu như Đại học Giao thông Tây An, Đại học Trùng Khánh,...
            </div>
            <div>
                ● Hán ngữ giúp chúng ta có thể hiểu rõ hơn về nền văn hóa và con người của đất nước tỷ dân này
            </div>
            <div>
                ● Trung Quốc đang là đất nước đang vươn lên dẫn đầu thế giới, cùng với các chính sách quan hệ giữa Việt Nam - Trung Quốc mở ra hàng nghìn cơ hội phát triển về việc làm với mức thu nhập hấp dẫn. 
            </div>
            <div>
                ● Với chính sách mở cửa giao thương giữa nước ta và nước bạn, nếu bạn biết sử dụng tiếng Trung sẽ giúp bạn dễ dàng trao đổi và buôn bán với người Trung Quốc
            </div>
            <div>
                Với những lý do trên đã đủ khiến bạn thấy vì sao tiếng Trung lại được quan tâm nhiều đến thế. 
            </div>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1.5rem 0 1rem;" id="new2">2. Lộ trình học tiếng Trung cho người mới bắt đầu 
        </div>
            <div>
                Sinh viên đăng ký chương trình dạy bằng tiếng Anh, ngoại trừ các chuyên ngành văn học, luật, triết học, lịch sử, giáo dục, nghệ thuật và y học cổ truyền Trung Quốc, cần tham gia các bài kiểm tra môn khoa học tự nhiên tương ứng là toán học, vật lý và hóa học.
                <div>
                    ● Sinh viên học các chuyên ngành kinh tế và quản lý tham gia bài kiểm tra toán học.
                </div>
                <div>
                    ● Sinh viên học các chuyên ngành khoa học tự nhiên, kỹ thuật và nông nghiệp cần tham gia bài kiểm tra toán học và dựa vào yêu cầu của chuyên ngành mà chọn một trong hai môn vật lý hoặc hóa học.
                </div>
                <div>
                    ● Sinh viên học các chuyên ngành y học phương Tây (dược phương Tây) phải tham gia bài kiểm tra toán học và hóa học.
                </div>
                <div id="new2_1">
                    <b>2.1. Ai nên học tiếng Trung cho người mới bắt đầu?</b> 
                </div>
                <div>
                    ● Các khóa học tiếng Trung cho người mới bắt đầu phù hợp với các học viên:
                </div>
                <div>
                    ● Bắt đầu nhập môn tiếng Trung từ con số 0
                </div>
                <div>
                    ● Bị mất gốc tiếng Trung trong khoảng thời gian dài 
                </div>
                <div>
                    ● Mục tiêu để thi HSK, thi tốt nghiệp THPT 
                </div>
                <div>
                    ● Phục vụ cho việc đi du học, du lịch, giải trí của bản thân như xem phim 
                </div>
                <div>
                    ● Yêu thích văn hóa Trung Hoa 
                </div>
                <div>
                    ● ●Phục vụ cho công việc liên quan tới tiếng Trung 
                </div>
                <div>
                    Các khóa học này với lộ trình tinh gọn, bài bản sẽ giúp bạn nắm chắc được nền tảng, phát âm điêu luyện và thành nào các bộ thủ và nét chữ. Đây đều là những điều tiên quyết cho hành trình học tiếng Trung và nâng cao trình độ của bản thân. 
                </div>
                <div id="new2_2">
                    <b>2.2. Học bảng chữ cái/cách phát âm </b> 
                </div>
                <div>
                    Với những người bắt đầu học tiếng Trung, bước đầu tiên bạn nên có thời gian làm quen với bảng chữ cái tiếng Trung. Luyện tập nhiều lần sẽ giúp bạn thành tạo, nhuần nguyễn cách đọc và phát âm Hán Ngữ. 
                    <br>
                    Để phát âm chuẩn bạn cần phải nắm được Thanh mẫu, Vận mẫu và Thanh điệu sau: 
                </div>
                <div>
                    ● Vận mẫu (hay còn được gọi là nguyên âm): 36 vận mẫu
                </div>
                <div>
                    ● Thanh điệu: gồm 4 dấu và 1 khinh thanh
                </div>
                <div>
                    Đa phần các giáo trình học tiếng Trung cho người mới bắt đầu đều có mô tả cách đặt lưỡi, lấy hơi phát âm sao cho đúng. Tuy nhiên, bạn nên kết hợp học qua video hoặc các thầy cô giảng viên có chuyên môn để đạt được hiệu quả tốt nhất
                </div>
                <div id="new2_3">
                    <b>2.3. Học quy tắc viết chữ Hán </b> 
                </div>
                <div>
                    Nắm chắc được cách phát âm tiếng Trung rồi, bước tiếp theo bạn cần làm là học các nét cơ bản trong Hán ngữ và quy tắc viết. Một trong những điều khiến Hán ngữ trở thành ngôn ngữ khó nhất hành tinh là vì chữ Hán là chữ tượng hình rất phức tạp và khó nhớ. Do vậy bạn cần phải nắm vững được 7 quy tắc viết chữ trong tiếng Trung. 
                </div>

                <div id="new2_4">
                    <b>2.4. Làm quen các bộ thủ tiếng Trung</b> 
                </div>
                <div>
                    Bên cạnh các viết chữ Hán, thì các bộ thủ cũng khiến bạn chùn lòng không ít. Vì có tận 214 bộ thủ và mỗi bộ lại có một ý nghĩa riêng biệt. Mỗi chữ trong Hán ngữ lại được hình thành từ một hoặc nhiều các bộ thủ khác nhau. Để việc ghi nhớ kiến thức này trở nên “dễ thở” hơn, bạn có thể lựa chọn học 50 bộ thủ thường dùng trước rồi thông qua việc học từ vựng bạn có thể trau dồi dần dần. 
                </div>
                <div  id="new2_5">
                    <b>2.5. Học kết hợp 4 kỹ năng nghe - nói - đọc - viết </b> 
                </div>
                <div>
                    Với người mới bắt đầu học tiếng Trung thì việc nhuần nhuyễn của 4 kỹ năng Nghe - Đọc - Nói - Viết là điều rất cần thiết cho việc nâng cao khả năng sau này. Với 4 kỹ năng này, các bạn có thể học từ các giáo trình cho người mới bắt đầu. Tuy nhiên, sẽ tốt hơn nếu bạn có người dẫn dắt có chuyên môn cao và dày dặn kinh nghiệm, kèm theo kết hợp luyện tập các kỹ năng: 
                </div>
                <div>
                    ● Luyện nói: Luyện nói trước gương, học theo youtube, và nói chuyện với giảng viên, bạn bè,...
                </div>
                <div>
                    ● Luyện nghe: Xem các video youtube, nghe nhạc Trung Hoa, hay xem phim,... là những phương pháp phổ biến mà hiệu quả
                </div>
                <div>
                    ● Luyện viết: thông qua những đoạn văn ngắn/dài,
                </div>
                <div>
                    ● Luyện đọc: đọc bài báo/sách tiếng Trung, hay đọc truyện Trung Quốc là những phương pháp không hề tệ,...
                </div>
                <div  id="new2_6">
                    <b>2.6. Học ngữ pháp</b> 
                </div>
                <div>
                    Học ngữ pháp là phần kiến thức cốt yếu của người học tiếng Trung nói riêng và người học ngoại ngữ nói chung. Để hiểu được câu diễn đạt gì, viết câu đúng theo trật tự thì bạn cần phải nắm chắc được cấu trúc ngữ pháp của Hán ngữ. Dưới đây là một số phương pháp giúp bạn có thể thuộc lòng ngữ pháp: 
                </div>
                <div>
                    ● Học theo giáo trình
                </div>
                <div>
                    ● Ghi chép, hệ thống và tổng hợp lại các chủ điểm ngữ pháp đã học
                </div>
                <div>
                    ● Đặt ví dụ cho từng ngữ pháp
                </div>
                <div>
                    ● Làm bài tập, luyện tập ngữ pháp
                </div>
                <div id="new2_7">
                    <b>2.7. Xây dựng vốn từ vựng</b> 
                </div>
                <div>
                    Trong giao tiếp bằng tiếng Trung hay thi HSK thì việc trau dồi vốn từ vựng là rất quan trọng. Đặc biệt từ năm 2021, kì thi HSK đã thay đổi từ 6 bậc sang 9 bậc với số lượng và cấp độ khó của từ vựng cũng tăng lên. Ngoài học trong sách, tài liệu, bạn có thể bổ sung thêm lượng từ vựng của mình bằng cách: 
                </div>
                <div>
                    ● Học qua flashcard
                </div>
                <div>
                    ● Học qua các từ đồng nghĩa trái nghĩa
                </div>
                <div>
                    ● Học từng vựng theo chủ đề
                </div>
                <div>
                    ● Học qua bài hát, phim ảnh
                </div>
                <div>
                    ● ●App học từ vựng
                </div>
                <div id="new2_8">
                    <b>2.8. Ôn tập thường xuyên</b> 
                </div>
                <div>
                    Học tiếng Trung cho người mới bắt đầu không thể thiếu được bước ôn tập. Để ghi nhớ được những kiến thức mình đã học thì bạn phải ôn luyện nhiều lần. Có nhiều hình thức để mình có thể ôn tập hiệu quả như làm bài tập, làm đề thi,... 
                </div>
        </div>
        <div style="font-weight: 600;font-size: 1.5rem;padding: 1.5rem 0 1rem;" id="new3">
            3. Học tiếng Trung cho người mới bắt đầu ở đâu uy tín, chất lượng?
        </div>
        <div>
            Với những bạn mới bắt đầu chưa rõ lộ trình nên bắt đầu từ đâu thì việc tham gia các lớp học tiếng Trung cho người bắt đầu là rất cần thiết. Vậy học ở đâu uy tín, chất lượng? 
            <br>
            EIC Education Việt Nam là chi nhánh của Tập đoàn Giáo dục hàng đầu Trung Quốc với hơn 24 năm kinh nghiệm. Các lớp học tại EIC cam kết chuẩn đầu ra cho các bạn học viên với:  <br>
            <div>
                ● Đội ngũ giảng viên người bản địacó chuyên môn cao và dày dặn kinh nghiệm là thạc sĩ tại các trường đại học hàng đầu Trung Quốc.
            </div>
            <div>
                ● Lộ trình học tinh gọn, bài bản với giáo trình chuẩn HSK
            </div>
            <div>
                ● Phương pháp giảng dạy hiệu quả độc quyền: Tam giác vàng, lấy học viên là trung tâm
            </div>
            <div>
                ● Đôi ngũ trợ giảng, cố vấn học tập hỗ trợ 24/7
            </div>
            <div>
                ● Hình thức online và offline thuận tiện cho mọi học viên .
            </div>
            <div>
                ● Học phí siêu hợp lý chỉ từ 50k/h 
            </div>
            <div>
                ● Cam kết chuẩn đầu ra cho học viên
            </div>
            <div>
                Trên đây là các cách <b>học tiếng Trung cho người mới bắt đầu. EIC Education Việt Nam</b> hi vọng với những thông tin trên có thể giúp ích cho những ai đang có mong muốn chinh phục ngôn ngữ này. Nếu các bạn có thắc mắc hay cần hỗ trợ có thể liên hệ EIC qua hotline/email để được giải đáp nhanh nhất. 
            </div>
            <div>
                <b>Thông tin liên hệ: </b> 
            </div>
            <div>
                <b>● Địa chỉ:</b> Tầng 7, 110 Phố Trần Vỹ, Phường Mai Dịch, Quận Cầu Giấy, Hà Nội
            </div>
            <div>
                <b>● Hotline/Zalo: </b>0906 225 916
            </div>
            <div>
                <b>● Email:</b> eiceducationvn@gmail.com
            </div>
        </div>
    </div>`,
    newsFooter:{
        title1:'VĂN PHÒNG VIỆT NAM',
        title2:'Các TIN TỨC liên quan',
        arr:[
            {
                title:'TÌM ĐÍCH ĐẾN CHO TƯƠNG LAI CÙNG CHÚNG TÔI',
                value:'Tìm trường, ngành và bắt đầu hành trình du học Trung Quốc ngay!',
                routerName:'Newsone',
                ishover:false,
            },
            {
                title:'EIC EDUCATION TỔ CHỨC DIỄN ĐÀN GIÁO DỤC QUỐC TẾ',
                value:'Vào ngày 27 tháng 10 năm 2023, diễn đàn Sáng kiến bồi dưỡng nhân tài học tập ở Trung Quốc thời đại mới - Góc nhìn từ tổ chức và doanh nghiệp...',
                routerName:'Newstwo',
                ishover:false,
            },
            {
                title:'7 LÝ DO DU HỌC TRUNG QUỐC: NỀN GIÁO DỤC VÀ KINH TẾ',
                value:'TVì sao du học Trung Quốc ngày càng trở nên phổ biến, đặc biệt là với học sinh Việt Nam chúng ta? Trung Quốc, với sự phát triển vượt bậc về kinh tế ...',
                routerName:'Newsthree',
                ishover:false,
            },
            {
                title:'"Hỏi & Đáp: Du học Trung Quốc - Tất tần tật về bài kiểm tra trình độ đầu vào mới',
                value:'Tính đến ngày 22 tháng 5 năm nay, chính thức có thông báo về việc du ...',
                routerName:'Newsfore',
                ishover:false,
            },
            {
                title:'Học tiếng Trung cho người mới bắt đầu nên bắt đầu từ đâu?',
                value:'Học tiếng Trung cho người mới bắt đầu nên bắt đầu từ đâu? Đây là một là một vấn đề luôn thường trực trong những người có nguyện vọng theo ...',
                routerName:'Newsfive',
                ishover:false,
            },
        ]
    }
})
// 用于查专业和查院校的枚举分类使用，
/**
 * 由前端根据pid进行分类展示,如：搜索label-入参code-对应的pId值
 * 查专业有：educationalLevel,majorType
 * 专业层次-educationalLevel-6,
 * 专业类型-majorType-1,
 * 
 * 查院校有：schoolRegion，schoolFeature，schoolEducation，educationalLevel，schoolType
 * 地区选择-schoolRegion-17,
 * 院校特色-schoolFeature-9，
 * 办学类型-schoolEducation-13，
 * 学历层次-educationalLevel-6，
 * 院校类型-schoolType-20,
 * 
 * 注意，对应的枚举值请求code的时候由英文的逗号进行链接【,】，这样可以请求到多个枚举的值，详细的在assets文件中有一个枚举值对应的pid，
 */
export const navChilder = ['school','serve','user']
export const country=ref(1)
export const submitForm = async()=>{
    try{
        const res = await fromsubmit('school',jsonObj.footerfrom)
        clearform()
      }catch(err){
        console.log(err)
      }
}
function generateUUID() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
export const clearform = ()=>{
    jsonObj.headerinput=''
    jsonObj.footerfrom={
        name:'',
        age:"",
        mobile:"",
        uv: window.localStorage.getItem("UV"),
        pv: generateUUID() + generateUUID(),
        email:"",
        academicDegree:"",
    }
    jsonObj.contactInput=''
}
export const getJsonHeaderandFooter = async()=>{
    let paramsHeader = {
        type:country.value,
        pageId:1
    }
    const resheader = await getJson(paramsHeader)
    let paramsFooter = {
        type:country.value,
        pageId:2
    }
    const resfooter = await getJson(paramsFooter)
}
export const routerPath = ref('/')
export const jumpRouter = async()=>{
    let pageId
    switch(routerPath.value){
        case '/home':
            pageId = 3
            break
        case '/specialty':
            pageId = 3
            break
        case '/schoolmajor':
            pageId = 3
            break
        case '/cultivate':
            pageId = 3
            break
        case '/studyAbroadLong':
            pageId = 3
            break
        case '/studyAbroadShort':
            pageId = 3
            break
        case '/story':
            pageId = 3
            break
        case '/partner':
            pageId = 3
            break
        case '/contact':
            pageId = 3
            break
        default:
          return
     }
    let paramsFooter = {
        type:country.value,
        pageId,
    }
    const resfooter = await getJson(paramsFooter)
}
watch(
    [country],
    (oldValue,newValue)=>{
        console.log(1231231)
    },{
        immediate:true
    }
)