import request from '@/api/index'
import axios from 'axios'
import {getUrl} from '../case'
let VITE_SCHOOL_URL
if(window.location.host === 'eiceducation.vn'){
    VITE_SCHOOL_URL = ' https://api.eiceducation.vn'
}else{
    VITE_SCHOOL_URL = 'https://vcc-api-test.eiceducation.com.cn'
}

export const enumlist = (type,data)=>{
    let url = getUrl(type)
    return axios.get(`${VITE_SCHOOL_URL}/api/lhEnum/index?code=${data.code}`)
}
export const schoolindex = (type,data)=>{
    let url = getUrl(type)
    return axios.get(`${VITE_SCHOOL_URL}/api/lhSchools/index?type=1&page=${data.page}&pageSize=${data.pageSize}&levelId=${data.levelId}&typeId=${data.typeId}
        &educationId=${data.educationId}&featureId=${data.featureId}&regionId=${data.regionId}&keyword=${data.keyword}`)
}
export const schooldetail = (type,data)=>{
    let url = getUrl(type)
    return axios.get(`${VITE_SCHOOL_URL}/api/lhSchools/detail?id=${data.id}`)
}
export const magorindex = (type,data)=>{
    let url = getUrl(type)
    return axios.get(`${VITE_SCHOOL_URL}/api/lhMajors/index?type=1&page=${data.page}&pageSize=${data.pageSize}&levelId=${data.levelId}&pageSize=${data.typeId}`)
}
export const magordetail = (type,data)=>{
    let url = getUrl(type)
    return axios.get(`${VITE_SCHOOL_URL}/api/lhMajors/detail?id=${data.id}`,data)
}


export const SearchMajor = (type,params={})=>{
    let url = getUrl(type)
    return request({
        url:VITE_SCHOOL_URL+"/api/v1/Search/Major",
        method:"GET",
        params,
    })
}

//流量统计
export const trafficStatistics = (type,data)=>{
    let url = getUrl(type)
    return axios.post(`${VITE_SCHOOL_URL}/api/lhFlow`,data)
}
