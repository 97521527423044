import request from '@/api/index'
import axios from 'axios'
let VITE_SCHOOL_URL
if(window.location.host === 'eiceducation.vn'){
    VITE_SCHOOL_URL = ' https://api.eiceducation.vn'
}else{
    VITE_SCHOOL_URL = 'https://vcc-api-test.eiceducation.com.cn'
}
export const getJson = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/yd/test/index?type='+params.type+"&pageId="+params.pageId).then(res=>{
            if(res.status == 200){
                resolve(res.data.results)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}

//获取首页轮播图
export const getCarouselImage = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/lhBanner/index').then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取项目列表
export const getProjectList = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/lhItem/index').then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取项目详情
export const getProjectDetail = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + `/api/lhItem/detail?id=${params.id}`).then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取新闻列表
export const getNewsList = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/lhNews/index?newType=1').then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取攻略列表
export const getHanhList = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/lhNews/index?newType=2').then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取文化列表
export const getConList = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/lhNews/index?newType=3').then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取新闻详情
export const getNewsDetail = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + `/api/lhNews/detail?id=${params.id}`).then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取活动列表
export const getActivityList = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/lhActivity/index').then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取活动详情
export const getActivityDetail = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + `/api/lhActivity/detail?id=${params.id}`).then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取院校列表
export const getSchoolList = (params={})=>{
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + '/api/yd/school/index').then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}
//获取专业列表
export const magorindex = (data={})=>{
    console.log(data);
    
    return new Promise((resolve, reject) => {
        axios.get(VITE_SCHOOL_URL + `/api/lhMajors/index?type=1&page=${data.page}&pageSize=${data.pageSize}&levelId=${data.levelId}&typeId=${data.typeId}&keyword=${data.keyword}`).then(res=>{
            if(res.status == 200){
                resolve(res.data.result)
            }
        }).catch(err=>{
            reject(err)
        })
    })
}